import { tinycolor, TinyColor } from "@ctrl/tinycolor"
import { LevelTile } from "./Level"

import { GameState } from "./game";

import { MapTileType, MapTileDef } from "./MapTiles"
import { App } from "./App"
import Item, { Player, Actor, Creature, Stair, Door, Gold, Sign } from "./GameObject";
var ccc = 0

export default class TileRenderer {
  static seed: number;
  static center: boolean = false
  static fontWidth: number;
  static tileSize: number;
  static fontFactor: number = 1;

  constructor() {
    TileRenderer.seed = new Date().getMilliseconds();
  }

  static cantor(a: number, b: number) {
    let rv = ((a + b) * (a + b + 1)) / 2 + a;
    return rv;
  }


  static grid2pixel(game: GameState, x: number, y: number): [number, number, boolean] {
    let cellWidth = Math.floor(TileRenderer.fontWidth)
    let rowHeight = Math.floor((Math.floor(TileRenderer.tileSize * TileRenderer.fontFactor) - 1))
    cellWidth = rowHeight = Math.max(cellWidth, rowHeight)

    let px = x * cellWidth
    let py = y * rowHeight
    let show = true

    if (px + App.console.context.offsetX < 0 || px + App.console.context.offsetX > App.console.canvas.width) {
      show = false
    }

    if (py + App.console.context.offsetY < 0 || py + App.console.context.offsetY > App.console.canvas.height) {
      show = false
    }
    return [px, py, show]
  }

  static renderLetter(game: GameState, x: number, y: number, char: string) {
    let show: boolean
    [x, y, show] = this.grid2pixel(game, x, y)
    if (!show)
      return
    App.console.context.fillText(char, x, y);
    ccc++
    return

  };


  static renderLevel(game: GameState): void {
    // Let me check, if the camera needs to be adjusted
    let obj = game.player!;
    App.console.context.textAlign = "center";

    let [tx, ty, show] = this.grid2pixel(game, obj.x, obj.y)
    tx += App.console.context.offsetX;
    ty += App.console.context.offsetY
    let dpr = window.devicePixelRatio || 1;
    if (obj instanceof Player) {
      let part = obj.energy / obj.maxEnergy;
      if (part >= 0.5) {
        App.console.context.font = "bold " + App.console.context.font;
        obj.energy -= 5;
      }
      let marginWidth = 0.33;

      if (tx * dpr > App.console.canvas.width * marginWidth * 2) {
        let diff = tx * dpr - App.console.canvas.width * marginWidth * 2;
        App.console.context.offsetX -= diff
      } else if (tx * dpr < App.console.canvas.width * marginWidth) {
        let diff = tx * dpr - App.console.canvas.width * marginWidth;
        App.console.context.offsetX -= diff
      }

      if (ty * dpr > App.console.canvas.height * marginWidth * 2) {
        let diff = ty * dpr - App.console.canvas.height * marginWidth * 2;
        App.console.context.offsetY -= diff
      } else if (ty * dpr < App.console.canvas.height * marginWidth) {
        let diff = ty * dpr - App.console.canvas.height * marginWidth;
        App.console.context.offsetY -= diff
      }
    }

    console.log("PROF", ccc)
    ccc = 0
    let redo = false;
    let level = game.currentLevel;
    if (level == null) return;
    let width = level.width
    let height = level.height

    if (App.console.context.offsetX === undefined) App.console.context.offsetX = 0;
    if (App.console.context.offsetY === undefined) App.console.context.offsetY = 0;

    //console.log(game.context.offsetX + " " + game.context.offsetY)
    App.console.context.setTransform(1, 0, 0, 1, 0, 0);

    App.console.context.scale(dpr, dpr);
    App.console.context.translate(App.console.context.offsetX, App.console.context.offsetY);
    // TODO Schwarz malen nur wenn nötig, also beim scrollen
    App.console.context.fillStyle = "#000";
    App.console.context.fillRect(-10000, -10000, 20000, 20000);

    // Setting up font sizes.
    // I moved that outside the inner loop, that was insane!
    TileRenderer.tileSize = Math.ceil(TileRenderer.tileSize);
    App.console.context.font = "normal " + TileRenderer.tileSize + "px DungeonFont";
    // TODO Optimze, do not do this all the time!
    TileRenderer.fontWidth = App.console.context.measureText(String.fromCharCode(0x2588)).width;
    TileRenderer.fontWidth = Math.floor(TileRenderer.fontWidth);

    // Internet Explorer 6-11
    var isIE = false; // /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) {
      alert("Still Edge?")
      TileRenderer.fontWidth = Math.ceil(1.25 * TileRenderer.fontWidth)
    }



    let x, y;
    for (y = 0; y < height; y++) {
      if (level.rows[y] == null) continue;
      for (x = 0; x < width; x++) {
        if (level.rows[y][x] == null) continue;
        let tile = level.rows[y][x].tile;
        if (tile !== undefined) {
          // TODO Nicht zeichnen, wenn belegt
          // Performance Übermalen vielleicht schneller als hier ermittelnz
          this.renderTile(game, x, y);
        }
      }
    }
  };


  static renderTile(game: GameState, x: number, y: number) {
    let levelTile: LevelTile = game.currentLevel.rows[y][x]
    if (!levelTile.explored)
      return

    let tileDef = MapTileDef.get(levelTile.tile)

    if (tileDef) {
      let [px, py, show] = TileRenderer.grid2pixel(game, x, y);
      if (!show)
        return

      let malAnleitung = tileDef.getRenderInfo(levelTile.visible, x, y)
      App.console.context.fillStyle = malAnleitung.bcolor
      //this.renderLetter(game, x, y, String.fromCharCode(0x2588))
      let cellWidth = Math.floor(TileRenderer.fontWidth)
      let rowHeight = Math.floor((Math.floor(TileRenderer.tileSize * TileRenderer.fontFactor) - 1))
      rowHeight = cellWidth = Math.max(cellWidth, rowHeight)
      if (malAnleitung.bcolor != "#000000") {
        App.console.context.fillRect(Math.floor(px - cellWidth * 0.5), Math.floor(py - rowHeight * 0.8), Math.ceil(cellWidth * 1.001), Math.ceil(rowHeight * 1.10))
      }

      // Objects are more important
      let samePlace = game.currentLevel.objects.filter((e: any) => {
        return x == (e.lastSeenX || e.x) && y == (e.lastSeenY || e.y);
      });
      if (samePlace.length > 0) {
        // Render the object
        let o = samePlace.sort((a: any, b: any) => {
          if (a instanceof Player) {
            return -100;
          }
          if (b instanceof Player) {
            return 100;
          } else if (b instanceof Actor) {
            return 50;
          }
          return 0;
        })[0];
        TileRenderer.simplifiedRenderObject(game, o);
        return;
      }
      // No objects, so go one with the background


      App.console.context.textAlign = "center";
      App.console.context.fillStyle = malAnleitung.fcolor
      this.renderLetter(game, x, y, malAnleitung.symbol)

      return
    }
  }


  static simplifiedRenderObject(game: GameState, obj: any) {
    const objx = obj.lastSeenX || obj.x;
    const objy = obj.lastSeenY || obj.y;
    let dark = tinycolor("000");
    let bright = tinycolor("efe0b9");
    if (!game.currentLevel.rows[objy][objx].visible) {
      [dark, bright] = [bright, dark];
    }

    App.console.context.fillStyle = dark.toHexString();
    let char = "?";

    switch (obj.constructor) {
      case Gold:
        char = "*";
        break;
      case Player:
        char = "@";
        if (obj.hitpoints <= 0) char = String.fromCharCode(0x2620);
        break;
      case Door:
        if (obj.state == 0) char = "_";
        else if (obj.state == 1) char = "+";
        else if (obj.state == 2 || (obj.state == 3 && !obj.lockRevealed)) {
          // - oder |
          let tileAbove = game.currentLevel.rows[objy - 1][objx];
          let tileBelow = game.currentLevel.rows[objy + 1][objx];

          let isTileAbove = MapTileDef.isWall(tileAbove.tile)

          let isTileBelow = MapTileDef.isWall(tileBelow.tile)
          if (isTileAbove || isTileBelow) char = "|";
          else char = "-";
        } else if (obj.state == 3 && obj.lockRevealed) {
          char = String.fromCharCode(0xd7);
        }
        break;
      case Creature:
      case Item:
      case Stair:
      case Sign:
        char = obj.character;
        break;
      default:
        if (char === "?") {
          console.log("define the character for " + obj.constructor);
          char = "?";
        }
    }

    this.renderLetter(game, objx, objy, char)
  }
}