import { TinyColor } from "@ctrl/tinycolor"
import TileRenderer from "./Renderer"

export { MapTileType, MapTileRenderInfo, MapTileDef }


enum MapTileType {
    Undefined = "Undefined", CaveFloor = "CaveFloor", CaveWall = "CaveWall", CityFloor = "CityFloor", CityWall = "CityWall", RuinFloor = "RuinFloor", RuinWall = "RuinWall", Water = "Water", Abyss = "Abyss", Pillar = "Pillar"
}

class MapTileRenderInfo {
    symbol: string = ''
    fcolor: string = "0xffffff"
    bcolor: string = "0x000000"
    italic: boolean = false
}

interface IRenderTile {
    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo;
}

class MapTileDef implements IRenderTile {
    protected static lightColor: TinyColor = new TinyColor("#efe0b9")
    protected static darkColor: TinyColor = new TinyColor("#000000")

    private static dic: Map<MapTileType, MapTileDef>
    public static add(tileDef: MapTileDef): void {
        if (MapTileDef.dic === undefined) {
            MapTileDef.dic = new Map<MapTileType, MapTileDef>()
        }
        MapTileDef.dic.set(tileDef.name, tileDef)
    }

    public static get(name: MapTileType): MapTileDef | undefined {
        return MapTileDef.dic.get(name)
    }

    public static isWall(tile: MapTileType): boolean {
        if (tile == MapTileType.CaveWall) return true
        if (tile == MapTileType.RuinWall) return true
        if (tile == MapTileType.CityWall) return true
        return false
    }

    name: MapTileType
    symbol: string
    blocksSight: boolean
    blocksPath: boolean
    collideMsg?: string

    constructor(which: MapTileType, symbol: string, blocks: boolean) {
        this.name = which
        this.symbol = symbol
        this.blocksSight = this.blocksPath = blocks

        MapTileDef.add(this)
    }

    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo {
        let rv = new MapTileRenderInfo()
        rv.symbol = this.symbol
        if (visible) {
            rv.fcolor = MapTileDef.darkColor.toHexString()
            rv.bcolor = MapTileDef.lightColor.toHexString()
        } else {
            rv.fcolor = MapTileDef.lightColor.toHexString()
            rv.bcolor = MapTileDef.darkColor.toHexString()
        }

        return rv
    }
}



let caveFloor = new MapTileDef(MapTileType.CaveFloor, ",", false)
let caveWall = new MapTileDef(MapTileType.CaveWall, "%", true)
caveWall.collideMsg = "You are standing near the cave's wall."


let cityFloor = new MapTileDef(MapTileType.CityFloor, ".", false)
let cityWall = new MapTileDef(MapTileType.CityWall, "#", true)
cityWall.collideMsg = "You are standing near a wall."

let pillar = new MapTileDef(MapTileType.Pillar, String.fromCharCode(0x229a), true)
pillar.collideMsg = "You are standing close to a massive pillar."

class WaterDef extends MapTileDef {
    constructor() {
        super(MapTileType.Water, "~", false)
    }

    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo {
        let rv = super.getRenderInfo(visible, x, y)
        let which = Math.random()
        if (!visible) {
            which = TileRenderer.cantor(x, y)
            which = which - Math.floor(which)
        }
        rv.symbol = which >= 0.5 ? String.fromCharCode(0x223c) : String.fromCharCode(0x223d)
        return rv
    }
}
let water = new WaterDef()


class RuinFloorDef extends MapTileDef {
    constructor() {
        super(MapTileType.RuinFloor, ".", false)
    }

    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo {
        let rv = super.getRenderInfo(visible, x, y);
        rv.italic = true
        return rv
    }
}

class RuinWallDef extends MapTileDef {
    constructor() {
        super(MapTileType.RuinWall, ",", true)
    }

    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo {
        let rv = super.getRenderInfo(visible, x, y);
        rv.italic = true
        return rv
    }
}

let ruinFloor = new RuinFloorDef()
let ruinWall = new RuinWallDef()
ruinWall.collideMsg = "You are standing near a ruined wall."

class AbyssDef extends MapTileDef {
    constructor() {
        super(MapTileType.Abyss, String.fromCharCode(0xb7), false)
        this.blocksPath = true
        this.blocksSight = false
    }

    getRenderInfo(visible: boolean, x: number, y: number): MapTileRenderInfo {
        let rv = super.getRenderInfo(visible, x, y);
        if (visible) {
            rv.bcolor = MapTileDef.darkColor.toHexString()
            rv.fcolor = MapTileDef.lightColor.toHexString()
        } else {
            rv.fcolor = MapTileDef.lightColor.darken(50).toHexString()
        }
        return rv
    }
}

let abyss = new AbyssDef()
abyss.collideMsg = "You are standing close to a bottomless abyss."
