import { GameObject, Actor, Player, Stair, StairType } from "./GameObject"
import { Map, MapTile } from "./MapGenerator";
import { MapTileType, MapTileDef } from "./MapTiles";
import { GameState } from "./game";

export type LevelTile = { tile: MapTileType, explored: boolean, visible: boolean }

export class Level {
  rows: LevelTile[][]
  objects: GameObject[]
  level: number
  seed: string
  tags: string[]
  size: number

  constructor(level: number, seed: string, tags: string[], map: Map) {
    const mapData: MapTile[] = map.tiles
    this.rows = [];
    this.objects = [];

    this.level = level;
    this.size = level;
    this.seed = seed;
    this.tags = tags;


    let x, y;

    for (y = 0; y < map.height; y++) {
      for (x = 0; x < map.width; x++) {
        let tile: MapTileType = mapData[y * map.width + x].type;
        this.set(x, y, tile);
        let item = mapData[y * map.width + x].item;
        if (item != undefined) {
          switch (item) {
            case 1:
              this.objects.push(new Stair(x, y, StairType.Downwards));
              break;
            case 2:
              this.objects.push(new Stair(x, y, StairType.Upwards));
              break;
          }
        }
      }
    }
  }

  get height(): number {
    return this.rows.length
  }

  get width(): number {
    return this.rows[0].length
  }

  getStairs(): Stair[] {
    let rv: Stair[] = this.objects.filter((a: GameObject) => {
      return (a instanceof Stair);
    }) as Stair[];
    return rv
  }

  getActors(): Actor[] {
    let rv: Actor[] = this.objects.filter((a: GameObject) => {
      return (a instanceof Actor);
    }) as Actor[]
    return rv
  }

  getEmptyTile(): { x: number, y: number } | undefined {

    const rounds = 200;
    for (let i = 0; i < rounds; i++) {
      let rx = Math.floor(Math.random() * this.width);
      let ry = Math.floor(Math.random() * this.height);
      if (this.isFloor(rx, ry)) {
        return { x: rx, y: ry };
      }
    }
    return undefined;
  }
  isFloor(x: number, y: number): boolean {
    if (!this.rows[y]) return false;
    if (!this.rows[y][x]) return false;
    if (this.rows[y][x].tile == MapTileType.CaveFloor) return true;
    if (this.rows[y][x].tile == MapTileType.RuinFloor) return true;
    if (this.rows[y][x].tile == MapTileType.CityFloor) return true;
    return false;
  }

  isOccupied(x: number, y: number): boolean {
    let idx = this.objects.findIndex((e: any) => {
      return e.x === x && e.y === y;
    });
    return idx != -1;
  }

  isBlocked(x: number, y: number): boolean {
    if (this.isFloor(x, y)) {
      if (!this.isOccupied(x, y)) {
        return false;
      } else {
        let objects = this.objects.filter(a => {
          return a.x == x && a.y == y;
        });
        for (let i = 0; i < objects.length; i++) {
          let object = objects[i];
          if (object.blocksPath) {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  }

  reveal(): void {
    for (let y = 0; y < this.height; y++)
      for (let x = 0; x < this.width; x++)
        this.rows[y][x].explored = true
  }
  setRow(nr: number, length: number, tile: any): void {
    this.rows[nr] = [];
    for (let i = 0; i < length; i++) {
      this.rows[nr][i] = { tile: tile, explored: false, visible: false };
    }
  }

  set(x: number, y: number, tile: MapTileType): void {
    if (this.rows[y] === undefined) {
      this.rows[y] = [];
    }
    this.rows[y][x] = { tile: tile, explored: false, visible: false };
  }

  get(x: number, y: number): MapTileType {
    return this.rows[y][x].tile
  }

  getObjectsAround(actor: GameObject) {
    let rv = [];
    // Direkt unter dem Spieler
    let o = this.objects.find((e: GameObject) => {
      return (
        e != actor && e.x == actor.x && e.y == actor.y && e.isUsedStandingUpon
      );
    });
    if (o) rv.push(o);

    // Nördlich des Spielers
    o = this.objects.find((e: GameObject) => {
      return e.x == actor.x && e.y == actor.y - 1 && e.isUsedStandingAside;
    });
    if (o) rv.push(o);

    // Südlich des Spielers
    o = this.objects.find(e => {
      return e.x == actor.x && e.y == actor.y + 1 && e.isUsedStandingAside;
    });
    if (o) rv.push(o);

    // Westlich des Spielers
    o = this.objects.find(e => {
      return e.x == actor.x - 1 && e.y == actor.y && e.isUsedStandingAside;
    });
    if (o) rv.push(o);

    // Östlich des Spielers
    o = this.objects.find(e => {
      return e.x == actor.x + 1 && e.y == actor.y && e.isUsedStandingAside;
    });
    if (o) rv.push(o);

    return rv;
  }

  makeViewMap(player: Player) {
    let count: number = 0
    let maxDistance = player.vision;
    let x = player.x
    let y = player.y
    this.rows[y][x].explored = true;

    for (let i = 0; i < this.height; i++) {
      for (let j = 0; j < this.rows[i].length; j++) {
        if (this.rows[i][j].tile == MapTileType.Undefined) continue;
        this.rows[i][j].visible = false;
      }
    }

    /*    for (let i = 0; i < this.getHeight(); i++) {
          for (let j = 0; j < this.rows[i].length; j++) {
            if (this.rows[i][j].tile == MapTileType.Undefined) continue;
            if (this.rows[i][j].visible == true) {
              continue;
            }*/
    for (
      var cy = y - Math.floor(maxDistance);
      cy <= y + Math.floor(maxDistance);
      cy++
    ) {
      if (cy < 0) continue;
      if (this.rows[cy] == undefined) continue;
      for (
        var cx = x - Math.floor(maxDistance);
        cx <= x + Math.floor(maxDistance);
        cx++
      ) {
        if (cx < 0 || cx >= this.rows[0].length) continue;
        if (this.rows[cy][cx].tile === MapTileType.Undefined) continue;
        if (this.rows[cy][cx].visible === true) continue;

        var dist = (cx - x) * (cx - x) + (cy - y) * (cy - y);
        if (dist <= maxDistance * maxDistance + 5) {
          if (dist < 4 && false) {
            this.rows[cy][cx].explored = this.rows[cy][cx].visible = true;
          } else {
            // Linie auf Hindernisse prüfen
            let lineCoords = Level.getLineCoords(x, y, cx, cy);
            count++
            let visible = true;
            for (let i = 0; i < lineCoords.length; i++) {
              if (this.rows[lineCoords[i].y] === undefined) continue;
              if (this.rows[lineCoords[i].y][lineCoords[i].x] === undefined)
                continue;
              let tile = this.rows[lineCoords[i].y][lineCoords[i].x].tile;
              if (tile !== MapTileType.Undefined) {
                if (MapTileDef.get(tile)?.blocksSight) {
                  visible = false;
                  break;
                }

                // Ich kontrolliere auch die Objekte, unter der Annahme, dass Objekte nur dort
                // existieren, wo auch Fliesen sind. Which is not true for wall-mounted signs.
                let samePlace = this.objects.findIndex(e => {
                  return e.x == lineCoords[i].x && e.y == lineCoords[i].y;
                });
                if (samePlace >= 0) {
                  if (this.objects[samePlace].blocksSight) {
                    visible = false;
                    break;
                  }
                }
              }
            }
            if (visible) {
              this.rows[cy][cx].explored = this.rows[cy][cx].visible = true;
              for (let j = 0; j < lineCoords.length; j++) {
                this.rows[lineCoords[j].y][
                  lineCoords[j].x
                ].explored = this.rows[lineCoords[j].y][
                  lineCoords[j].x
                ].visible = true;
              }
            }
          }
        }
      }
      //        }
      //      }
    }
    console.log("PROF", `makeViewMap() called getLineCoords() ${count} times.`)
    // Post process
    let countPp1 = 0
    for (let cy = 0; cy < this.height; cy++) {
      for (let cx = 0; cx < this.width; cx++) {
        if (this.rows[cy] != null) {
          if (this.rows[cy][cx].tile != MapTileType.Undefined) {

            let tile = this.rows[cy][cx].tile;
            if (
              !MapTileDef.get(tile)?.blocksSight &&
              this.rows[cy][cx].visible
            ) {
              countPp1++;
              let dx = cx - x;
              let dy = cy - y;
              let direction: ("n" | "no" | "o" | "so" | "s" | "sw" | "w" | "nw") = "n";

              if ((dx === 0) && (dy < 0)) direction = "n";
              else if (dx > 0 && dy < 0) direction = "no";
              else if (dx > 0 && dy === 0) direction = "o";
              else if (dx > 0 && dy > 0) direction = "so";
              else if (dx === 0 && dy > 0) direction = "s";
              else if (dx < 0 && dy > 0) direction = "sw";
              else if (dx < 0 && dy === 0) direction = "w";
              else if (dx < 0 && dy < 0) direction = "nw";
              // 3.1 Look to the left
              if (
                direction === "n" ||
                direction === "s" ||
                direction === "sw" ||
                direction === "w" ||
                direction === "nw"
              ) {
                let mapTile = this.rows[cy][cx - 1];
                if (mapTile !== undefined) {
                  if (MapTileDef.get(mapTile.tile)?.blocksSight) {
                    mapTile.explored = mapTile.visible = true;
                  }
                  // Auch eine geschlossen Tür sollte sich verhalten wie eine Wand
                  let samePlace = this.objects.find(e => {
                    return e.x == cx - 1 && e.y == cy;
                  });
                  if (samePlace !== undefined) {
                    if (samePlace.blocksSight) {
                      mapTile.explored = mapTile.visible = true;
                    }
                  }
                }
              }

              // 3.2 Look to the right
              if (
                direction === "n" ||
                direction === "no" ||
                direction === "o" ||
                direction === "so" ||
                direction === "s"
              ) {
                let mapTile = this.rows[cy][cx + 1];
                if (mapTile !== undefined) {
                  if (MapTileDef.get(mapTile.tile)?.blocksSight) {
                    mapTile.explored = mapTile.visible = true;
                  }
                  // Auch eine geschlossen Tür sollte sich verhalten wie eine Wand
                  let samePlace = this.objects.find((e: any) => {
                    return e.x == cx + 1 && e.y == cy;
                  });
                  if (samePlace !== undefined) {
                    if (samePlace.blocksSight) {
                      mapTile.explored = mapTile.visible = true;
                    }
                  }
                }
              }
              // 3.3 Look to the top
              if (
                (
                  direction == "n" ||
                  direction == "no" ||
                  direction == "o" ||
                  direction == "w" ||
                  direction == "nw"
                ) && cy > 0
              ) {
                let mapTile = this.rows[cy - 1][cx];

                if (MapTileDef.get(mapTile.tile)?.blocksSight) {
                  if (this.rows[cy][cx].visible)
                    mapTile.explored = mapTile.visible = true;
                }
                // Auch eine geschlossen Tür sollte sich verhalten wie eine Wand
                let samePlace = this.objects.find(e => {
                  return e.x == cx && e.y == cy - 1;
                });
                if (samePlace !== undefined) {
                  if (samePlace.blocksSight) {
                    mapTile.explored = mapTile.visible = true;
                  }
                }
              }
              // 3.4 Look to the bottom
              if (
                (
                  direction == "o" ||
                  direction == "so" ||
                  direction == "s" ||
                  direction == "sw" ||
                  direction == "w"
                ) &&
                this.rows[cy + 1] != undefined
              ) {
                let mapTile = this.rows[cy + 1][cx];
                if (mapTile !== undefined) {
                  if (MapTileDef.get(mapTile.tile)?.blocksSight) {
                    mapTile.explored = mapTile.visible = true;
                  }
                  // Auch eine geschlossen Tür sollte sich verhalten wie eine Wand
                  let samePlace = this.objects.find(e => {
                    return e.x == cx && e.y == cy + 1;
                  });
                  if (samePlace !== undefined) {
                    if (samePlace.blocksSight) {
                      mapTile.explored = mapTile.visible = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    console.log("PROF", "pp1 " + countPp1)

    // Ecken korrigieren // TODO Aufräumen/neu Benennen
    let countPp2 = 0
    for (let cy = Math.max(1, y - player.vision); cy < Math.min(this.height, y + player.vision); cy++) {
      for (let cx = Math.max(1, x - player.vision); cx < Math.min(this.width, x + player.vision); cx++) {
        countPp2++
        let mapTile1: LevelTile
        let mapTile2: LevelTile;

        mapTile1 = this.rows[cy - 1][cx - 1];
        mapTile2 = this.rows[cy - 1][cx];

        let mapTile3: LevelTile = this.rows[cy][cx - 1];
        let mapTile4: LevelTile = this.rows[cy][cx];

        if (
          // Links unten und rechts oben
          MapTileDef.get(mapTile1.tile)?.blocksSight &&
          mapTile1.visible &&
          MapTileDef.get(mapTile4.tile)?.blocksSight &&
          mapTile4.visible
        ) {
          if (MapTileDef.get(mapTile3.tile)?.blocksSight && !MapTileDef.get(mapTile2.tile)?.blocksSight) {
            mapTile3.explored = mapTile3.visible = true;
          }
          if (MapTileDef.get(mapTile2.tile)?.blocksSight && !MapTileDef.get(mapTile3.tile)?.blocksSight) {
            mapTile2.explored = mapTile2.visible = true;
          }
        }

        if (
          // Rechts unten und Links oben
          MapTileDef.get(mapTile2.tile)?.blocksSight &&
          mapTile2.visible &&
          MapTileDef.get(mapTile3.tile)?.blocksSight &&
          mapTile3.visible
        ) {
          if (MapTileDef.get(mapTile4.tile)?.blocksSight && !MapTileDef.get(mapTile1.tile)?.blocksSight) {
            mapTile4.explored = mapTile4.visible = true;
          }

          if (MapTileDef.get(mapTile1.tile)?.blocksSight && !MapTileDef.get(mapTile4.tile)?.blocksSight) {
            mapTile1.explored = mapTile1.visible = true;
          }
        }
      }
    }
    console.log("PROF", "pp2 " + countPp2)
  }



  static getLineCoords = function (x0: number, y0: number, x1: number, y1: number): { x: number, y: number }[] {
    let rv: { x: number, y: number }[] = [];
    let dx = x1 - x0;
    let dy = y1 - y0;

    let sx = Math.sign(dx);
    let sy = Math.sign(dy);

    // Diagonale
    if (Math.abs(dx) === Math.abs(dy)) {
      let cx = x0 + sx;
      let cy = y0 + sy;
      while (cx != x1) {
        rv.push({ x: cx, y: cy });
        cx += sx;
        cy += sy;
      }
      return rv;
    }

    // X ist schneller
    if (Math.abs(dx) > Math.abs(dy)) {
      let cx = x0 + sx;
      while (cx != x1) {
        let curDeltaX = cx - x0;
        let steigung = (curDeltaX * dy) / dx;
        let cy = Math.round(y0 + steigung + 0.01 * sy);
        rv.push({ x: cx, y: cy });
        cx += sx;
      }
      return rv;
    }

    // Y ist schneller
    if (Math.abs(dy) > Math.abs(dx)) {
      let cy = y0 + sy;
      while (cy != y1) {
        let curDeltaY = cy - y0;
        let steigung = (curDeltaY * dx) / dy;
        let cx = Math.round(x0 + steigung + 0.01 * sx);
        rv.push({ x: cx, y: cy });
        cy += sy;
      }
      return rv;
    }
    return rv
  }

  static load(source: any): Level {
    let mapTile: MapTile = { type: MapTileType.Undefined }
    let map: Map = { width: 1, height: 1, tiles: [mapTile] }
    let rv = new Level(source.level, source.seed, source.tags, map)
    GameState.game.levels[source.level] = rv
    for (let y = 0; y < source.rows.length; y++) {
      rv.rows[y] = []
      for (let x = 0; x < source.rows[y].length; x++) {
        let loaded = source.rows[y][x]
        let lt: LevelTile = { tile: loaded.tile, explored: loaded.explored, visible: loaded.visble }
        rv.rows[y][x] = lt
      }
    }
    for (let i = 0; i < source.objects.length; i++) {
      let obj = GameObject.load(source.objects[i])
      if (obj !== undefined) {
        rv.objects.push(obj)
      }
    }


    return rv
  }
}