import Item, { GameObject, Actor, Player } from "./GameObject";
import { GameState } from "./game"
import { App } from "./App"

export class Inventory {
  maxSize: number;
  map: Map<string, Item>

  static load(def: any): Inventory {
    let rv = new Inventory(def.maxSize)

    return rv
  }
  constructor(max: number) {
    this.maxSize = max;
    this.map = Object.create(null);
  }

  get size() {
    let rv = 0;
    for (var propt in this.map) {
      if (propt.length == 1 && propt === propt.toLowerCase()) {
        rv++;
      }
    }
    return rv;
  }

  get values() {
    let rv = [];
    for (var propt in this.map) {
      if (propt.length == 1 && propt === propt.toLowerCase()) {
        const newLocal = (<any>this.map)[propt];
        rv.push(newLocal);
      }
    }
    return rv;
  }

  get keys() {
    let rv = [];
    for (var propt in this.map) {
      if (propt.length == 1 && propt === propt.toLowerCase()) {
        rv.push(propt);
      }
    }
    return rv;
  }

  hasKey(key: string) {
    return this.keys.includes(key);
  }

  add(item: GameObject): [boolean, string] {
    if (this.size >= this.maxSize) {
      return [false, "You can not carry any more items."];
    } else {
      // Find an empty slot
      let start = 97;
      let char;
      do {
        char = String.fromCharCode(start);
        start++;
      } while (this.hasKey(char));
      (<any>this.map)[char] = item;
      return [true, "You carry the " + item.name + " with you."];
    }
  }

  list2() {
    if (this.size == 0) {
      App.console.add("Your inventory is empty.", true);
      return;
    } else {
      let [keys, values, infos] = this.filterInventory();

      App.console.pickAndInvestManual(keys, values, infos);
      App.console.add("Press Escape to cancel", true);
      return [false, ""];
    }
  }

  filterInventory(filter: Function = () => true) {

    let keys: string[] = [];
    let values: any[] = [];
    let infos: string[] = [];
    let slots: string[] = this.keys;

    slots.forEach(char => {
      let item = (<any>this.map)[char];
      let rv = filter(item);
      if (rv != false) {
        keys.push(char);
        values.push(item.name + (item.equipped == true ? " (equipped)" : ""));
        infos.push(item.description);
      }
    });

    return [keys, values, infos];
  }

  quaff(actor: Actor): [boolean, string] {
    if (!(actor instanceof Player))
      return [false, ""]

    let con = App.console;

    let [keys, values, infos] = this.filterInventory(function (i: any) {
      let rv = i.hasTag("potion")[0];
      return rv;
    });
    if (keys.length == 0) {
      con.add("You do not carry anything to quaff.", true);
      return [false, ""];
    }

    con.add("What do you want to quaff? Choose and confirm.", true);

    con.pickAndChooseOneManual(keys, values, infos, (b: any) => {
      let item = actor.inventory.map.get(b)
      item?.quaff(actor);
      actor.inventory.map.delete(b)
    });
    con.add("Press Escape to cancel", true);
    return [false, ""];
  }

  equip(actor: Actor): [boolean, string] {
    if (!(actor instanceof Player))
      return [false, ""]

    let [keys, values, infos] = this.filterInventory(function (i: any) {
      let rv =
        i.hasTag("melee")[0] || i.hasTag("armor")[0] || i.hasTag("shield")[0];
      return rv && !i.equipped;
    });
    if (keys.length == 0) {
      App.console.add("You do not carry anything to equip.", true);
      return [false, ""];
    }

    App.console.add("What do you want to equip? Choose and confirm.", true);

    App.console.pickAndChooseOneManual(keys, values, infos, (b: any) => {
      let item = actor.inventory.map.get(b);
      if (item) {
        let duration = item.equip(actor);
        actor.nextAct += duration;
        App.console.updateStatus(GameState.game);
      }
    });
    App.console.add("Press Escape to cancel", true);
    return [false, ""];
  }

  unequip(actor: Actor): [boolean, string] {
    if (!(actor instanceof Player))
      return [false, ""]

    let [keys, values, infos] = this.filterInventory(function (i: any) {
      return i.equipped;
    });
    if (keys.length == 0) {
      App.console.add("You have not equipped anything.", true);
      return [false, ""];
    }

    App.console.add("What do you want to cast off? Choose and confirm.", true);

    App.console.pickAndChooseOneManual(keys, values, infos, (b: any) => {
      let item = actor.inventory.map.get(b);
      if (item) {
        let duration = item.unequip();
        actor.nextAct += duration;
        App.console.updateStatus(GameState.game);
      }
    });
    App.console.add("Press Escape to cancel", true);
    return [false, ""];
  }

  drop(actor: Actor): [boolean, string] {
    if (!(actor instanceof Player))
      return [false, ""]
    if (this.size == 0) {
      return [false, "You do not carry anything to get rid of."];
    }

    // check position of the player
    let player = GameState.game.player;
    let samePlace = GameState.game.currentLevel.objects
      .filter((e: any) => {
        return e.x == actor.x && e.y == actor.y;
      })
      .filter((e: any) => {
        return e.char != "@";
      });

    if (samePlace.length > 0) {
      return [false, "This place is already occupied by something."];
    }

    App.console.add("What do you want to get rid of? Choose and confirm.", true);

    let [keys, values, infos] = this.filterInventory();

    App.console.pickAndChooseOneManual(keys, values, infos, (b: string) => {
      let item = actor.inventory.map.get(b);
      if (item) {
        App.console.add("You dropped the " + item.name + ".");
        actor.nextAct += item.drop(actor, b);
        App.console.updateStatus(GameState.game);
      }
    });
    App.console.add("Press Escape to cancel", true);
    return [false, ""];
  }
}
