import { Inventory } from "./Inventory";
import { GameState } from "./game"
import { MapTileType, MapTileDef } from "./MapTiles"
import { Level } from "./Level"
import { App } from "./App"
import Polyfills from "./Polyfills"
import TileRenderer from "./Renderer"

Polyfills()



export abstract class GameObject {
    name: string = ""
    description: string = ""
    // TODO static game: GameState;
    x: number = NaN;
    y: number = NaN;
    character: string;
    tags: string[]; // TODO Maybe an enum
    protected _blocksSight: boolean;
    protected _blocksPath: boolean;

    protected _healingCounter: number = 0;
    dexterity: number = 0;
    level: any; // TODO I better fix that soon


    private static containsTag(tags: string[], tag: string): boolean {
        let tagCount = tags.filter(a => {
            return a.indexOf(tag) == 0;
        }).length;
        return tagCount > 0
    }
    static load(def: any): (Player | Sign | Item | undefined) {

        if (GameObject.containsTag(def.tags, "player")) {
            let rv: Player = new Player(def.x, def.y)
            rv = Object.assign(rv, def)
            let inv = Inventory.load(def.inventory)
            rv.inventory = inv
            return rv
        } else if (GameObject.containsTag(def.tags, "sign")) {
            let rv: Sign = new Sign(def.x, def.y, def.message)
            rv = Object.assign(rv, def)
            return rv
        } else if (GameObject.containsTag(def.tags, "ring")) {
            let rv: Item = new Item(def.x, def.y, {})
            rv = Object.assign(rv, def)
            return rv
        }
        return undefined

    }
    collide(actor: Actor): number {
        return NaN
    }

    standUpon(actor: Actor): number {
        return NaN
    }
    constructor(x: number, y: number, char: string) {

        this.x = x;
        this.y = y;
        this.character = char;
        this.tags = [];
        this._blocksSight = false;
        this._blocksPath = this.blocksSight;
    }

    determineHitpoints(dieSize: number, minimum: number = 0) {
        let rv = dieSize;
        /* TODO
        for (let i = 1; i < this.level; i++) {
            rv += 0;GameState.throwDie(dieSize);
        }*/
        return Math.max(rv, minimum);
    }

    /**
     * @param {number} value
     */

    get blocksSight() {
        return this._blocksSight;
    }

    get blocksPath() {
        return this._blocksPath;
    }

    hasTag(tagName: string) {
        if (this.tags == null) return [false];

        let tags = this.tags.filter(a => {
            return a.indexOf(tagName.toLowerCase()) == 0;
        });
        if (tags.length == 0) return [false];
        return [true, tags[0]]; // Tag zurückgeben, weil auch eine Nummer daran hängen kann
    }

    hasAnyOfTags(tags: string[]): boolean {
        tags.forEach(element => {
            let has = this.hasTag(element)[0];
            if (has)
                return true;
        });
        return false;
        /*
        return tags.reduce((accu: boolean, item: string) => {
          if (!accu) accu = false;
          accu = accu || this.hasTag(item);
        });*/
    }

    hasAllOfTags(tags: string[]) {
        let counter = 0;
        tags.forEach(tag => {
            let has = this.hasTag(tag)[0]
            if (has) {
                counter++
            }
        })
        return counter == tags.length

        /*
        return tags.reduce((accu, item) => {
          if (!accu) accu = true;
          accu = accu && this.hasTag(item);
        });*/
    }

    setPos(x: number, y: number): void {
        this.x = x;
        this.y = y;
    }

    get char(): string {
        return this.character;
    }

    get isUsedStandingUpon(): boolean {
        return !this.isUsedStandingAside;
    }

    get isUsedStandingAside(): boolean {
        return this.blocksPath;
    }

    static attack(attacker: Actor, defender: Actor): number {
        console.log("attack value ", attacker.attackValue);
        console.log("defense value ", defender.defenseValue);

        let a = 0.5; //TODO GameState.toChance(attacker.attackValue);
        let d = 0.5 // TODO  GameState.toChance(defender.defenseValue);
        console.log("attack chance ", a);
        console.log("defense chance ", d);
        let hit = Math.random() * a > d / 2;

        if (hit) {
            let damage = Math.max(1, 3 /* TODO GameState.throwDie(attacker.actualStrength)*/);
            /* TODO
                        if (attacker instanceof Player) {
                            GameState.game.console.add(
                                "You attack the " +
                                defender.name +
                                " and cause " +
                                damage +
                                " points of damage."
                            );
                            defender.hitpoints -= damage;
                        } else if (defender instanceof Player) {
                            GameState.game.console.add(
                                "The " +
                                attacker.name +
                                " hits you and causes " +
                                damage +
                                " points of damage."
                            );
                            defender.hitpoints -= damage;
                            GameState.game.console.updateStatus(GameState.game);
                        }*/
        } else {
            /* TODO
            if (attacker instanceof Player) {
                GameState.game.console.add(
                    "You attack the " + defender.name + " but miss."
                );
            } else if (defender instanceof Player) {
                GameState.game.console.add(
                    "The " + attacker.name + " attacks you but misses."
                );
            }*/
        }

        return attacker.speed;
    };
}



export abstract class Actor extends GameObject {
    abstract act(): number
    inventory: Inventory = new Inventory(10)
    protected strength: number = 0
    speed: number
    energy: number = 0
    maxEnergy: number = 1
    protected _hitpoints: number = 0;
    maxHitpoints: number = 0;
    gold: number = 0
    nextAct: number = 0
    gender: ('male' | 'female' | 'unknown') = 'unknown'

    constructor(x: number, y: number, char: string, speed: number) {
        super(x, y, char)
        this.speed = speed
        this.nextAct = (this.speed || 0) + Math.random();
    }

    get actualStrength() {
        let rv = this.level - 1;
        rv += this.strength;

        if (this.inventory) {
            // TODO rings, health conditions, potions, ...
            let items = this.inventory.values;
            items.forEach((item: any) => {
                if (item.hasTag("ring")[0]) {
                    if (item.hasTag("strength")[0]) {
                        rv += item.option;
                    }
                }
            });
        }
        return rv;
    }

    get actualDexterity() {
        let rv = this.level - 1;
        rv += this.dexterity;
        if (this.inventory) {
            // TODO rings, health conditions, potions, ...
            let items = this.inventory.values;
            items.forEach((item: any) => {
                if (item.hasTag("ring")[0]) {
                    if (item.hasTag("dexterity")[0]) {
                        rv += item.option;
                    }
                }
            });
        }
        return rv;
    }

    get defenseValue() {
        let rv = this.actualDexterity;

        if (this.inventory) {
            // TODO rings, health conditions, potions, ...
            let values = this.inventory.values;
            values.forEach((item: any) => {
                if (item.equipped) {
                    if (
                        item.hasTag("armor")[0] ||
                        item.hasTag("shield")[0]
                    ) {
                        let supportsDefense = item.hasTag("defense");
                        if (supportsDefense[0]) {
                            rv += (<any>supportsDefense[1]).extractValue();
                        }
                    }
                }
            });
        }

        return rv;
    }
    get attackValue() {
        let rv = this.actualStrength;

        if (this.inventory) {
            // TODO rings, health conditions, potions, ...
            let values = this.inventory.values;
            values.forEach((item: any) => {
                if (item.equipped) {
                    if (item.hasTag("melee")[0]) {
                        let supportsAttack = item.hasTag("attack");
                        if (supportsAttack[0]) {
                            rv += (<any>supportsAttack[1]).extractValue();
                        }
                    }
                }
            });
        }
        return rv;
    }

    set hitpoints(value) {
        if (this._hitpoints > value) {
            this._healingCounter = 0;
        }
        this._hitpoints = value;
        if (this._hitpoints > this.maxHitpoints) {
            this._hitpoints = this.maxHitpoints;
        }
        /* TODO 
                if (this instanceof Creature) {
                    if (this._hitpoints <= 0 && this.dying) {
                        this.dying();
                    }
                }*/
    }

    get hitpoints() {
        return this._hitpoints;
    }
}

export class Player extends Actor {
    dead: boolean;
    gold: number;
    validActions: string
    vision: number
    private initialHitpoints: number
    healingInterval: number
    race: any;
    objectsAround: any;
    constructor(x: number, y: number) {
        super(x, y, "@", 16);
        this.dead = false;
        this.tags.push("player");
        this.gold = 0;
        this.validActions = "";
        this.vision = 4; // 5.3
        this.strength = 0;
        this.dexterity = 0;
        this.level = 1;
        this.initialHitpoints = 8;
        this.maxHitpoints = this.determineHitpoints(this.initialHitpoints);
        this._hitpoints = this.maxHitpoints;
        // healing
        this.healingInterval = 50;
        this._healingCounter = 0;
        this._blocksPath = true;
        this._blocksSight = false;
    }
    actorDiscriminator: boolean = true;
    /**
     * Return NaN when the program is handled, returning a number when
     * something in the game took time, even if 0 ticks
     */
    act(): number {
        if (App.keyStore.length == 0 || GameState.game.player === undefined) {
            GameState.game.save();
            return NaN;
        }

        App.console.resetChangeIndicators();
        let event = App.keyStore.shift()!;

        if (document.getElementById("helpscreen")!.style.display != "none") {
            if (
                event.key == "Escape" ||
                App.isKey(
                    event.keyCode,
                    App.keybindings.meta.toggleHelpScreen
                )
            ) {
                document.getElementById("helpscreen")!.style.display = "none";
            }
            return NaN;
        }

        if (document.getElementById("feedbackscreen")!.style.display != "none") {
            if (
                event.key == "Escape" ||
                App.isKey(
                    event.keyCode,
                    App.keybindings.meta.toggleFeedbackDialog
                )
            ) {
                document.getElementById("feedbackscreen")!.style.display = "none";
            }
            return NaN;
        }


        if (App.console.interactive != "no") {
            return NaN;
        }
        let game = GameState.game;
        let pressedKey = event.key;
        if (App.console.iteratorMode.active) {
            game.displayCursor();
            switch (pressedKey) {
                case "Escape":
                case "ArrowUp":
                    App.console.iteratorMode.active = false;
                    App.console.iteratorMode.iterator = undefined;
                    // ToDo nicht immer alles neu!
                    TileRenderer.renderLevel(game);
                    return NaN;
                case "e":
                case "d":
                case "PageDown":
                case "ArrowRight":
                case "Tab":
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                    event.preventDefault();
                    App.console.iteratorMode.iterator += 1;
                    break;
                case "q":
                case "a":
                case "PageUp":
                case "ArrowLeft":
                    App.console.iteratorMode.iterator -= 1;
                    break;
                case App.console.iteratorMode.key:
                case "Enter": {
                    let focusedObject =
                        App.console.iteratorMode.relevantObjects[
                        App.console.iteratorMode.iterator %
                        App.console.iteratorMode.relevantObjects.length
                        ];
                    if ((<any>focusedObject).performAction)
                        (<any>focusedObject).performAction(game.player, App.console.iteratorMode.key);

                    let displayActions = this.updateValidActions();

                    if (displayActions.occurrences("<br>", false) == 1) {
                        displayActions = "&#x23ce;, " + displayActions;
                    }
                    App.console.debug(displayActions);
                    App.console.removeTemporary();
                    App.console.iteratorMode.active = false;
                    App.console.iteratorMode.iterator = undefined;
                    // ToDo nicht immer alles neu!
                    game.currentLevel.makeViewMap(this);
                    TileRenderer.renderLevel(game);
                    return NaN;
                }
            }

            // ToDo nicht immer alles neu!
            TileRenderer.renderLevel(game);

            GameState.game.displayCursor();
            return NaN;
        }

        if (window.location.hostname.indexOf("localhost") != -1) {
            if (event.key == "R") {
                game.currentLevel.reveal();
                TileRenderer.renderLevel(game);
            }
            if (event.key == "H") {
                this.hitpoints = this.maxHitpoints;
                App.console.updateStatus(game);
            }
            if (event.key == "L") {
                this.levelUp();
                App.console.updateStatus(game);
            }
        }

        let allEqual = this.validActions
            .split("")
            .every((char: string) => char === this.validActions[0]);
        if (allEqual && event.key == "Enter") {
            pressedKey = this.validActions[0];
        }


        let ticks = 0;
        if (this.validActions.indexOf(pressedKey) >= 0) {
            let count = this.validActions.occurrences(pressedKey, false);

            if (count > 1) {
                App.console.add("Which one?", true);
                let help = "d,&#x2192;,&#x2B7E; next<br>a,&#x2190; previous<br>";
                help += "&#x23ce;, " + pressedKey + " confirm<br>";
                help += "&#x241b; cancel";
                App.console.debug(help);
                App.console.iteratorMode.active = true;
                App.console.iteratorMode.key = pressedKey;

                App.console.iteratorMode.relevantObjects = this.objectsAround.filter((e: GameObject) => {
                    if (!instanceOfUsable(e))
                        return false
                    return e.getActions(this).findIndex((f: any) => {
                        return f == pressedKey;
                    });
                });
                game.displayCursor();
                return NaN;
            } else {
                let objectsAround = game.currentLevel.getObjectsAround(this);

                for (let i = 0; i < objectsAround.length; i++) {
                    let o = objectsAround[i]

                    if (!instanceOfUsable(o))
                        continue
                    let commands = o.getActions(this);
                    let hasCommand = commands.some((e: any) => {
                        return e[0] == pressedKey;
                    });
                    if (hasCommand) {
                        ticks = o.performAction(this, pressedKey);
                        if (ticks > 0) {
                            //game.currentLevel.makeViewMap(this);
                            //TileRenderer.renderLevel(game);
                            //GameState.advanceGame();
                        }
                        break;
                    }
                }
            }
        }

        if (
            !App.isKey(event.key, App.keybindings.meta.zoomIn) &&
            !App.isKey(event.key, App.keybindings.meta.zoomOut) &&
            Math.round(this.hitpoints) >= 1 &&
            ticks == 0
        ) {
            ticks = this.move(event.key, game);
            if (ticks > 0) App.console.removeTemporary();
        }

        if (ticks > 0) {
            this.validActions = "";
        } else {
            if (App.isKey(event.key, App.keybindings.meta.inventory)) {
                //game.console.add(game.player.inventory.list(), true);
                this.inventory.list2();
            } else if (App.isKey(event.key, App.keybindings.potion.quaff)) {
                let rv = this.inventory.quaff(this);
                App.console.add(rv[1]);
            } else if (App.isKey(event.key, App.keybindings.item.equip)) {
                let rv = this.inventory.equip(this);
                App.console.add(rv[1]);
            } else if (App.isKey(event.key, App.keybindings.item.unequip)) {
                let rv = this.inventory.unequip(this);
                if (rv) App.console.add(rv[1]);
            } else if (App.isKey(event.key, App.keybindings.player.drop)) {
                // Wenn ich auf einer Treppe stehe, soll gar nichts passieren, weil die Keys identisch sind.
                let samePlace = false;
                if (
                    App.keybindings.player.drop == App.keybindings.stairs.go
                ) {
                    samePlace =
                        GameState.game.currentLevel.objects
                            .filter((e: any) => {
                                return e.x == this.x && e.y == this.y;
                            })
                            .filter((e: any) => {
                                return GameState.hasTag(e, "stair")[0];
                            }).length > 0;
                }
                if (!samePlace) {
                    App.console.removeTemporary();
                    let rv = this.inventory.drop(this);
                    if (rv[1].length > 0) App.console.add(rv[1]);
                }
            }
        }

        // Analyse possible actions and display to the player
        if (App.console.lastId) App.console.remove(App.console.lastId)
        let displayActions = this.updateValidActions()

        if (displayActions.occurrences("<br>", false) === 1) {
            displayActions = "&#x23ce;, " + displayActions
        }
        App.console.debug(displayActions)
        return ticks // TODO Das ist Schwachsinn
    }

    set healingCounter(value) {
        this._healingCounter = Math.min(value, this.healingInterval);
        if (this.hitpoints == this.maxHitpoints) {
            this._healingCounter = 0;
        }
    }

    get healingCounter() {
        return this._healingCounter;
    }

    healing() {
        while (this._healingCounter >= this.healingInterval) {
            if (this.hitpoints < this.maxHitpoints) {
                this._healingCounter -= this.healingInterval;
                this.hitpoints++;
            } else {
                break;
            }
        }
    }

    levelUp() {
        this.level++;
        this.maxHitpoints = this.determineHitpoints(
            this.initialHitpoints,
            this.maxHitpoints
        );
    }

    applyRace(race: any) {
        let pl = this.inventory;
        this.race = race;
        race.tags.forEach((t: string) => {
            let relative = t.indexOf("+") != -1 || t.indexOf("-") != -1;

            let stat = Object.keys(this).find(a => {
                return t.startsWith(a.toLowerCase());
            });

            if (stat) {
                if (relative) {
                    (<any>this)[stat] += t.extractValue();
                } else {
                    (<any>this)[stat] = t.extractValue();
                }
            }

            if (t.toLowerCase().startsWith("inventory")) {
                let change = t.extractValue();
                let size = pl.maxSize + change;
                pl = new Inventory(size);
            }

            if (t == "maleOnly" || t == "femaleonly" || t.startsWith("inventory")) {
                // this was only relevant for character creation
            } else {
                this.tags.push(t);
            }
        });
        this.inventory = pl;
    }

    updateValidActions() {
        let displayActions = "";
        this.validActions = "";
        let objectsAround = GameState.game.currentLevel.getObjectsAround(this);
        objectsAround.forEach((o: GameObject) => {
            if (instanceOfUsable(o)) {
                let actions = o.getActions(this);
                actions.forEach((e: any) => {
                    if (displayActions.indexOf(e) == -1) displayActions += e + "<br>";
                    this.validActions += e[0];
                });
            }
        });
        this.objectsAround = objectsAround;
        return displayActions;
    }

    move(which: string, game: GameState) {
        this.healing();
        let level = game.currentLevel;
        var duration = 0;
        var nx = this.x;
        var ny = this.y;

        if (App.isKey(which, App.keybindings.player.up)) {
            ny -= 1;
            duration = this.speed;
        } else if (App.isKey(which, App.keybindings.player.right)) {
            nx += 1;
            duration = this.speed;
        } else if (App.isKey(which, App.keybindings.player.down)) {
            ny += 1;
            duration = this.speed;
        } else if (App.isKey(which, App.keybindings.player.left)) {
            nx -= 1;
            duration = this.speed;
        } else if (App.isKey(which, App.keybindings.player.pause)) {
            duration = 1;
            // Pausing doubles the healing process
            this.healingCounter++;
            return duration;
        }

        // Check wall collision
        if (!level.rows[ny]) return 0;
        var mapTile = level.rows[ny][nx];
        if (mapTile !== undefined) {
            let tile: MapTileType = mapTile.tile;
            if (tile !== undefined) {
                let tileDef = MapTileDef.get(tile)
                if (tileDef?.blocksPath) {
                    if (tileDef.collideMsg) App.console.add(tileDef.collideMsg);
                    return 0;
                }
                // Auch eine geschlossen Tür sollte sich verhalten wie eine Wand
                let samePlace = level.objects.find((e: GameObject) => {
                    return e.x == nx && e.y == ny && e.blocksPath;
                });
                if (samePlace !== undefined) {
                    if (samePlace.blocksPath) {
                        if (samePlace.collide) {
                            return samePlace.collide(this);
                        }
                        return 0;
                    }
                }
            }
        } else {
            return 0;
        }

        if (game.currentLevel.rows[this.y][this.x].tile == MapTileType.Water) {
            duration *= 2;
        }
        this.x = nx;
        this.y = ny;

        if (duration > 0) {
            // Handle overlap, do not draw two letters in the same place
            let samePlace = level.objects.findIndex((e: any) => {
                return e.x == nx && e.y == ny && e != this;
            });
            if (samePlace >= 0) {
                let overlap = level.objects[samePlace];
                if (overlap.standUpon && overlap.standUpon(this)) {
                    if (overlap instanceof Gold)
                        level.objects.splice(samePlace, 1);
                }
            }
            // Handle stuff like wall-mounted signs, that are neighbours like closed doors
            let objectsAround = game.currentLevel.getObjectsAround(this);
            objectsAround.forEach((o: GameObject) => {
                if (o.isUsedStandingAside)
                    duration += o.standUpon(this)
            });

        }
        level.makeViewMap(this);
        //    level.makeViewMap(this.x, this.y);

        return duration;
    }
}

export class Creature extends Actor {
    dead: boolean = false
    previouslyVisible: boolean = false
    name: string; // TODO better in GameObject
    description: string; // TODO better in GameObject
    vision: number
    behavior: string // TODO Very bad choice
    lastSeenX: number = Number.NaN
    lastSeenY: number = Number.NaN

    constructor(x: number, y: number, creatureDefinition: any) {
        super(x, y, "?", creatureDefinition.speed || 16);
        this.tags = creatureDefinition.tags;
        this.name = creatureDefinition.name;
        this.level = creatureDefinition.level;
        this.description = creatureDefinition.description;

        this.character = creatureDefinition.char || this.name[0];
        this.dexterity = 0;

        this.maxHitpoints = this.determineHitpoints(6);
        this._hitpoints = this.maxHitpoints;

        this.speed = 32;
        this.vision = 13;
        this._blocksPath = true;
        this.behavior = creatureDefinition.behavior || "basic1";

        if (this.hasTag("epic")[0]) {
            this.character = this.character.toUpperCase();
        }
        if (this.hasTag("huge")[0]) {
            this._blocksSight = true;
        }
    }
    actorDiscriminator: boolean = true

    collide(actor: Actor): number {
        return GameObject.attack(actor, this);
    }

    dying() {
        App.console.add("The " + this.name + " died.");
        this.dead = true;
        GameState.game.currentLevel.objects = GameState.game.currentLevel.objects.filter(
            (v: GameObject) => {
                return v != this;
            }
        );
    }

    canSeePlayer(): boolean {
        // Check distance first
        let dx = GameState.game.player!.x - this.x;
        let dy = GameState.game.player!.y - this.y;
        let distance = Math.sqrt(dx * dx + dy * dy);
        if (this.vision < distance) return false;
        // Noe check tiles in between
        let coordinates = Level.getLineCoords(
            this.x,
            this.y,
            GameState.game.player!.x,
            GameState.game.player!.y
        );

        for (let i = 0; i < coordinates.length; i++) {
            let coordinate = coordinates[i];
            let isBlocked = GameState.game.currentLevel.isBlocked(
                coordinate.x,
                coordinate.y
            );
            if (isBlocked) return false;
        }

        return true;
    }

    act(): number {
        if (this.dead) return 0;

        let walkOptions: string[] = [];
        let lastTile = GameState.game.currentLevel.rows[this.y][this.x];

        switch (this.behavior) {
            case "basic1":
                {
                    let objectsAround = GameState.game.currentLevel.getObjectsAround(
                        this
                    );

                    if (objectsAround.length > 0) {
                        let pl = objectsAround.find((e: GameObject) => {
                            return e instanceof Player
                        }) as Player;
                        if (pl) {
                            let duration = GameObject.attack(this, pl);
                            this.nextAct += duration;
                            return duration;
                        }
                    }
                    if (GameState.game.player && this.canSeePlayer()) {
                        let dx = GameState.game.player.x - this.x;
                        let dy = GameState.game.player.y - this.y;
                        // determine step options
                        if (dx < 0 && Math.abs(dx) > Math.abs(dy)) {
                            // west
                            walkOptions = ["w", "ns", "e"];
                        } else if (dx > 0 && Math.abs(dx) > Math.abs(dy)) {
                            // east
                            walkOptions = ["e", "ns", "w"];
                        } else if (dy < 0 && Math.abs(dy) > Math.abs(dx)) {
                            // north
                            walkOptions = ["n", "we", "s"];
                        } else if (dy > 0 && Math.abs(dy) > Math.abs(dx)) {
                            // south
                            walkOptions = ["s", "we", "n"];
                        } else if (dx < 0 && dy < 0 && Math.abs(dx) == Math.abs(dy)) {
                            // north west
                            walkOptions = ["nw", "se"];
                        } else if (dx > 0 && dy < 0 && Math.abs(dx) == Math.abs(dy)) {
                            // north east
                            walkOptions = ["ne", "sw"];
                        } else if (dx < 0 && dy > 0 && Math.abs(dx) == Math.abs(dy)) {
                            // south west
                            walkOptions = ["sw", "ne"];
                        } else if (dx > 0 && dy > 0 && Math.abs(dx) == Math.abs(dy)) {
                            // south east
                            walkOptions = ["se", "nw"];
                        }

                        for (let i = 0; i < walkOptions.length; i++) {
                            walkOptions[i] = walkOptions[i].shuffle();
                        }
                        let compressedWalkOptions: string = walkOptions.join("");
                        let i;
                        for (i = 0; i < compressedWalkOptions.length; i++) {
                            let dir = compressedWalkOptions.charAt(i);
                            let dx = 0, dy = 0;
                            switch (dir) {
                                case "n":
                                    dx = 0;
                                    dy = -1;
                                    break;
                                case "s":
                                    dx = 0;
                                    dy = +1;
                                    break;
                                case "w":
                                    dx = -1;
                                    dy = 0;
                                    break;
                                case "e":
                                    dx = +1;
                                    dy = 0;
                                    break;
                            }
                            if (
                                !GameState.game.currentLevel.isBlocked(
                                    this.x + dx,
                                    this.y + dy
                                )
                            ) {
                                this.x += dx;
                                this.y += dy;
                                i = 10000;
                            }
                        }
                        if (i != 10001) {
                        }
                    }
                }
                break;

            default:
        }

        let currentTile = GameState.game.currentLevel.rows[this.y][this.x];
        if (currentTile.visible) {
            this.lastSeenX = this.x;
            this.lastSeenY = this.y;
            if (!this.previouslyVisible) {
                let showMessage = function (item: GameObject) {
                    let msg = "You see a";
                    if (item.name.match(/[aeio].*/g)) {
                        msg += "n";
                    }
                    msg += " " + item.name + ".";
                    App.console.add(msg, false);
                };
                let listStr = window.sessionStorage.getItem("seenCreatures");
                let list = []
                if (listStr == null) {
                    list.push(this.name);
                    showMessage(this);
                    window.sessionStorage.setItem("seenCreatures", JSON.stringify(list));
                } else {
                    let array = JSON.parse(listStr);
                    if (!array.includes(this.name)) {
                        showMessage(this);
                        array.push(this.name);
                        window.sessionStorage.setItem(
                            "seenCreatures",
                            JSON.stringify(array)
                        );
                    } else {
                        // simply do nothing
                    }
                }

                this.previouslyVisible = true;
            }
        }
        let delay = this.speed;
        if (lastTile.tile == MapTileType.Water) {
            delay *= 2;
        }
        return delay;
    }
}



declare global {
    interface UsableInterface {
        usableDiscriminator: boolean;
        getActions(actor: Actor): string[];
        performAction(actor: Actor, key: string): number;
    }


}

(<any>window).instanceOfUsable = function (object: any): object is UsableInterface {
    return 'usableDiscriminator' in object
}



export default class Item extends GameObject implements UsableInterface {
    usableDiscriminator: boolean = true
    option: any // What is this?
    equipped: boolean = false
    constructor(x: number, y: number, itemDefinition: any) {
        super(x, y, "?");
        if (itemDefinition.name === undefined)
            return
        this.tags = itemDefinition.tags;
        this.tags.push("item");
        this.name = itemDefinition.name;
        this.description = itemDefinition.description;
        if (itemDefinition.options) {
            this.option = itemDefinition.options.randomElement();
        }

        // TODO Optical info belongs in the renderer
        if (GameState.hasTag(this, "ring")[0]) {
            this.character = String.fromCharCode(0x2604); // 29c2
            // TODO this.name += " " + this.option <= 0 ? "" : "+" + this.option;
        }
        if (GameState.hasTag(this, "potion")[0]) {
            this.character = String.fromCharCode(0x26b1);
        }
        if (GameState.hasTag(this, "melee")[0]) {
            this.character = String.fromCharCode(0x2020);
        }

        if (GameState.hasTag(this, "armor")[0]) {
            this.character = String.fromCharCode(0x26f9); // 0x212b, 0x1330
        }
        if (GameState.hasTag(this, "shield")[0]) {
            this.character = String.fromCharCode(0x26c9);
        }
    }

    standUpon(actor: Actor): number {
        if (!(actor instanceof Player)) return 0;
        App.console.add("You found a " + this.name);
        return 2;
    }

    getActions(actor: Actor): string[] {
        let rv: string[] = [];
        if (!(actor instanceof Player)) return rv;
        rv.push(App.keybindings.item.take + " to take");
        if (GameState.hasTag(this, "potion")[0]) {
            rv.push(App.keybindings.potion.quaff + " to quaff");
        }
        if (
            this.hasTag("melee")[0] ||
            this.hasTag("armor")[0] ||
            this.hasTag("shield")[0]
        ) {
            rv.push(App.keybindings.item.equip + " to equip");
        }
        return rv;
    }

    performAction(actor: Actor, key: string) {

        if (App.isKey(key, App.keybindings.item.take)) {
            let rv = actor.inventory.add(this);
            App.console.add(rv[1]);
            if (rv[0]) {
                GameState.game.currentLevel.objects = GameState.game.currentLevel.objects.filter(
                    (el: GameObject) => {
                        return el != this;
                    }
                );
            }
            return 1;
        }

        if (App.isKey(key, App.keybindings.item.equip)) {
            let rv = actor.inventory.add(this);
            App.console.add(rv[1]);
            if (rv[0]) {
                let duration = this.equip(actor);
                if (duration > 0) {
                    GameState.game.currentLevel.objects = GameState.game.currentLevel.objects.filter(
                        (el: GameObject) => {
                            return el != this;
                        }
                    );
                }
                return duration;
            }
            return 1;
        }

        if (App.isKey(key, App.keybindings.potion.quaff)) {
            let duration = this.quaff(actor);
            if (duration > 0) {
                GameState.game.currentLevel.objects = GameState.game.currentLevel.objects.filter(
                    (el: GameObject) => {
                        return el != this;
                    }
                );
            }
            return duration;
        }
        return 0;
    }

    drop(actor: Actor, b: string) {
        this.equipped = false;
        this.x = actor.x;
        this.y = actor.y;
        GameState.game.currentLevel.objects.push(this);
        actor.inventory.map.delete(b);
        return 1;
    }

    unequip() {
        this.equipped = false;
        return 1;
    }

    equip(actor: Actor) {
        let duration = 1;
        // TODO all other weapons, armors, shields, ... unequip
        if (
            this.hasTag("melee")[0] ||
            this.hasTag("armor")[0] ||
            this.hasTag("shield")[0]
        ) {
            if (this.hasTag("melee")[0] || this.hasTag("shield")[0]) {
                App.console.add("You ready the " + this.name);
                duration = 3;
            } else if (this.hasTag("armor")[0]) {
                App.console.add("You put on the " + this.name);
                duration = 5;
            }
            let slots = actor.inventory.keys;
            slots.forEach((key: string) => {
                let el = actor.inventory.map.get(key);
                if (el && el.equipped) {
                    if (
                        (el.hasTag("melee")[0] && this.hasTag("meelee")[0]) ||
                        (el.hasTag("armor")[0] && this.hasTag("armor")[0]) ||
                        (el.hasTag("shield")[0] && this.hasTag("shield")[0])
                    ) {
                        let rv = key + ") " + el.name + " gets unequipped.";
                        duration += 1;
                        el.equipped = false;
                    }
                }
            });
            this.equipped = true;
        }
        return duration;
    }

    quaff(actor: Actor) {
        let rv = this.hasTag("health");
        if (rv[0]) {
            if (typeof rv[1] === 'string') {
                let value = rv[1].extractValue();
                if (actor instanceof Player)
                    App.console.add("You quaffed the " + this.name);
                actor.hitpoints += value;
                App.console.updateStatus(GameState.game);
            }
        }
        return 1;
    }
}

export enum StairType { Downwards = "Downwards", Upwards = "Upwards", OneWayDownwards = "OneWayDownwards" }
export class Stair extends GameObject implements UsableInterface {
    kind: StairType
    constructor(x: number, y: number, kind: StairType) {
        super(x, y, "S");
        this.kind = kind;
        this.tags.push("stair");

        switch (kind) {
            case StairType.Downwards:
                this.character = String.fromCharCode(0x2198);
                break;
            case StairType.Upwards:
                this.character = String.fromCharCode(0x2197);
                break;
            case StairType.OneWayDownwards:
                this.character = String.fromCharCode(0x21a7);
                break;
        }
    }
    usableDiscriminator: boolean = true;

    performAction(actor: Actor, key: string): number {
        if (this.kind == StairType.Downwards || this.kind == StairType.OneWayDownwards) {
            return this.goDownwards(actor);
        } else { // this.kind == StairType.Upwards
            return this.goUpwards(actor);
        }
    }

    goUpwards(actor: Actor) {
        let game = GameState.game;

        // Remove player from current level
        game.currentLevel.objects = game.currentLevel.objects.filter((a: GameObject) => {
            return !(a instanceof Player);
        });

        // Korrespondierende Treppe auf dem anderen Level finden
        // get index of current Stair item
        let allStairsHere = game.currentLevel.getStairs().filter((e: Stair) => {
            return e.kind == StairType.Upwards;
        });

        let stairsIndex = allStairsHere.findIndex((a: Stair) => {
            return a.x == this.x && a.y == this.y;
        });

        game.currentLevelIndex--;
        game.currentLevel.objects.push((<GameObject>actor));

        let allStairsThere = game.currentLevel.getStairs().filter((e: Stair) => {
            return e.kind == StairType.Downwards;
        });
        let otherEnd = allStairsThere[stairsIndex];
        if (otherEnd && game.player) {
            game.player.x = otherEnd.x;
            game.player.y = otherEnd.y;
        }
        return 12 * 3;
    }

    goDownwards(actor: Actor) {
        let game = GameState.game;
        game.currentLevel.objects = game.currentLevel.objects.filter((a: GameObject) => {
            return !(a instanceof Player);
        });
        // Korrespondierende Treppe auf dem anderen Level finden
        // get index of current Stair item
        let allStairsHere = game.currentLevel.getStairs().filter((e: Stair) => {
            return e.kind == StairType.Downwards;
        });
        let stairsIndex = allStairsHere.findIndex((a: Stair) => {
            return a.x == this.x && a.y == this.y;
        });
        game.currentLevelIndex++;
        game.currentLevel.objects.push(actor);

        let allStairsThere = game.currentLevel.getStairs().filter((e: Stair) => {
            return e.kind == StairType.Upwards;
        });
        let otherEnd = allStairsThere[stairsIndex];

        if (otherEnd) {
            actor.x = otherEnd.x;
            actor.y = otherEnd.y;
        }
        return 12 * 2;
    }

    getActions(actor: Actor) {
        console.log("Stair.getActions()")
        let rv = [];
        if (!(actor instanceof Player)) return [];
        if (this.kind == StairType.Downwards || this.kind == StairType.OneWayDownwards)
            rv.push(App.keybindings.stairs.go + " go downwards");
        if (this.kind == StairType.Upwards)
            rv.push(App.keybindings.stairs.go + " go upwards");
        return rv;
    }

    standUpon(actor: Actor): number {
        switch (this.kind) {
            case StairType.Downwards:
                App.console.add(
                    "You are standing near a passage leading downwards."
                );
                break;
            case StairType.Upwards:
                App.console.add(
                    "You are standing near a passage leading upwards."
                );
                break;
            case StairType.OneWayDownwards:
                App.console.add(
                    "You are standing near a one-way path leading downwards."
                );
                break;
        }
        return NaN;
    }
}

export enum DoorState { Open = "Open", Closed = "Closed", Locked = "Locked", KickedDown = "KickedDown" }

export class Door extends GameObject implements UsableInterface {
    state: DoorState // Better enum
    lockRevealed: boolean = false
    lock: number = 0

    get isUsedStandingAside() {
        return true;
    }

    collide(actor: Actor): number {
        if (actor === GameState.game.player) {
            if (this.state == DoorState.Locked && this.lockRevealed)
                App.console.add("You bumped into a locked door.");
            else App.console.add("You bumped into a door.");
        }
        return 1;
    }

    standUpon(actor: Actor) {
        if (!(actor instanceof Player)) {
            App.console.add("You are standing inside a door frame.");
        }
        return NaN;
    }

    constructor(x: number, y: number, state: any, lock?: number) {
        super(x, y, "D");
        this.tags.push("door");
        this.state = state;
        if (lock != undefined) {
            {
                this.lock = Math.abs(lock) * -1;
            }
        }
    }
    usableDiscriminator: boolean = true;
    performAction(actor: Actor, key: string) {
        if (key === App.keybindings.door.open) return this.open()
        if (key === App.keybindings.door.close) return this.close();
        if (key === App.keybindings.door.kickDown) return this.kick(actor);
        return 0
    }

    open(/* actor */) {
        if (this.state === DoorState.Closed) {
            this.state = DoorState.Open;
            App.console.add("You opened the closed door");
            return 12;
        }
        if (this.state === DoorState.Locked && !this.lockRevealed) {
            App.console.add("The door is locked");
            this.lockRevealed = true;
            return 6;
        }
        return 0
    }

    close(/* actor */) {
        if (this.state == DoorState.Open) {
            this.state = DoorState.Locked;
            App.console.add("You closed the open door");
            return 12;
        }
        return 0
    }

    kick(actor: Actor) {
        if (this.state == DoorState.Closed || this.state == DoorState.Locked) {
            let strength = actor.actualStrength;
            let chance = GameState.toChance(strength);
            console.log("kick down chance " + chance);
            if (chance > Math.random()) {
                this.state = DoorState.KickedDown;
                App.console.add("You kicked down the door");
                return 10;
            } else {
                App.console.add("You failed kicking down the door");
                actor.hitpoints -= GameState.throwDie(6);
                if (actor.hitpoints < 1) {
                    App.console.add(
                        "You died on dungeon level " +
                        GameState.game.currentLevelIndex +
                        " kicking down a door unsuccessfully."
                    );
                }
                return 15;
            }
        }
        return 0
    }

    get blocksSight() {
        let rv = this.state == DoorState.Closed || this.state == DoorState.Locked;
        return rv;
    }

    get blocksPath() {
        return this.blocksSight;
    }

    get char() {
        switch (this.state) {
            case DoorState.KickedDown:
                return (
                    String.fromCharCode(0x258f) +
                    String.fromCharCode(0x2595) +
                    String.fromCharCode(0x2594) +
                    String.fromCharCode(0x2582)
                );
            case DoorState.Open:
                return String.fromCharCode(0x258e) + String.fromCharCode(0x2595);
            case DoorState.Closed:
                return String.fromCharCode(0x2588);
            case DoorState.Locked:
                if (this.lockRevealed)
                    return (
                        String.fromCharCode(0x2588) + "\t" + String.fromCharCode(0x26bf)
                    );
                else return String.fromCharCode(0x2588);
            default:
                console.error("Error while picking chars for the Door");
                return "?";
        }
    }

    getActions(actor: Actor) {
        let rv = [];
        if (!(actor instanceof Player)) return [];
        let objectsInPlace = GameState.game.currentLevel.objects.filter((a: any) => {
            return a.x == this.x && a.y == this.y && a._blocksPath;
        });
        let blocked = objectsInPlace.length > 0;
        if (this.state == DoorState.Open && !blocked)
            rv.push(App.keybindings.door.close + " close door");
        if (this.state == DoorState.Closed || (this.state == DoorState.Locked && !this.lockRevealed))
            rv.push(App.keybindings.door.open + " open door");
        if (this.state == DoorState.Closed || this.state == DoorState.Locked) {
            if (!blocked)
                rv.push(App.keybindings.door.kickDown + " kick door down");
        }
        return rv;
    }
}



export class Gold extends GameObject {
    value: number // Better Interfave Valuable
    constructor(x: number, y: number, value: number) {
        super(x, y, "*");
        this.tags.push("gold");
        this.value = value;
    }

    standUpon(actor: Actor): number {

        actor.gold += this.value;
        if (actor instanceof Player) {
            App.console.add(`You found ${this.value} gold coins.`);
        }
        App.console.updateStatus(GameState.game);
        return 1;
    }
}

export class Sign extends GameObject {
    msg: string
    constructor(x: number, y: number, message: string) {
        super(x, y, String.fromCharCode(0x2139))
        this.tags.push("sign")
        this.msg = message

        let wallMounted = MapTileDef.get(GameState.game.currentLevel.get(x, y))!.blocksPath
        this._blocksPath = this._blocksSight = wallMounted
    }

    standUpon(actor: Actor): number {
        if (actor instanceof Player) {
            if (this._blocksPath) {
                App.console.add(`A writing on the wall reads <em>${this.msg}</em>.`)
            } else {
                App.console.add(`The sign reads <em>${this.msg}</em>.`)
            }
        }
        return 1; // TODO Bad reading requires more time! Length of text influences time!
    }
}