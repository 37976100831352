/**
 *
 * GameState is the object that, when saved and reloaded, can recreate the whole game.
 * @export
 * @class GameState
 */

import { Level } from "./Level";
import { App } from "./App";
import { GameObject, Player, Actor, Stair } from "./GameObject";
import TileRenderer from "./Renderer";
import { Story } from "./Story";

export class GameState {
  static story: Story
  creatureDefs: any[] = []
  itemDefs: any[] = []
  raceDefs: any[] = []
  levels: Level[];
  currentLevelIndex: number;
  ticks: number;
  consoleHistory: string = ""; // TODO What is it really?
  static game: GameState; // TODO Make the whole class static?

  /**
   *Creates an instance of GameState.
   * @memberof GameState
   */

  constructor() {
    /** @member {Object} The size (width and height) of a single tile */
    TileRenderer.tileSize = 20;
    this.levels = [];
    this.currentLevelIndex = 1;
    this.ticks = 0;

    TileRenderer.fontWidth = App.console.context.measureText(String.fromCharCode(0x2588)).width;
    GameState.game = this
  }

  get currentLevel(): Level {
    return this.levels[this.currentLevelIndex];
  }




  public static toChance(nummer: number) {
    let rv = -5 / (nummer + 6) + 1;
    return rv;
  };

  public static throwDie = function (sides: number) {
    return Math.floor(Math.random() * sides) + 1;
  };

  public static hasTag(o: GameObject, tagName: string): [boolean, string?] {
    if (o.tags == null) return [false];

    let tags = o.tags.filter(a => {
      return a.indexOf(tagName.toLowerCase()) == 0;
    });
    if (tags.length == 0) return [false];
    return [true, tags[0]]; // Tag zurückgeben, weil auch eine Nummer daran hängen kann
  };

  save() {
    if (App.console.interactive === "no") {
      this.consoleHistory = App.console.content;
      window.localStorage.setItem("savedGame", JSON.stringify(this));
    }
  }

  get player(): Player {
    let objects = this.levels[this.currentLevelIndex].objects;
    let player = objects.find((e: GameObject) => {
      return e instanceof Player
    });
    return player as Player
  }

  static load(json: string): GameState {
    let o = JSON.parse(json)
    let rv = new GameState()

    rv.creatureDefs = o.creatureDefs
    rv.raceDefs = o.raceDefs
    rv.itemDefs = o.itemDefs
    rv.currentLevelIndex = o.currentLevelIndex
    rv.ticks = o.ticks

    for (let i = 0; i < o.levels.length; i++) {
      let olevel = o.levels[i]
      if (olevel !== null) {
        rv.levels[i] = Level.load(olevel)
      }
    }

    GameState.game = rv;
    App.console.content = o.consoleHistory;
    return rv;
  }

  advanceGame() {
    if (App.console.interactive !== "no") {
      this.player.act()
      return;
    }

    if (this.currentLevel === undefined)
      return
    let lateActors = this.currentLevel.getActors()
      .filter((a: any) => {
        return a.nextAct < GameState.game.ticks;
      });
    lateActors.forEach((a: any) => {
      a.nextAct = this.ticks + (a.nextAct - Math.floor(a.nextAct));
    });


    var actingActors: Actor[] = this.currentLevel.getActors()
      .filter((a: any) => Math.floor(a.nextAct) == GameState.game.ticks);

    if (actingActors.length > 0) {
      actingActors.sort((a: any, b: any) => {
        return a.nextAct - b.nextAct;
      });

      for (let a of actingActors) {
        const duration = a.act()
        if (a instanceof Player) {
          if (isNaN(duration)) {
            // Abbruch, der Spieler denkt
            return
          }
        }
        if (duration > 0) {
          a.nextAct += duration
        }
      }
      //  GameState.game.currentLevel.makeViewMap(GameState.game.player!);
      TileRenderer.renderLevel(GameState.game)
    }

    if (GameState.game.player && GameState.game.player.hitpoints < 1) {
      window.localStorage.removeItem("savedGame");
      App.console.updateStatus(GameState.game);
      App.console.add("What to you want to do?", true);
      App.console.add("a) Restart", true);
      App.console.add("b) Send feedback to the developer", true);
      App.console.pickOne("ab", (b: string) => {
        if (b == "a") {
          window.location.reload();
          return;
        } else {
          document.getElementById("feedbackscreen")!.style.display = "initial";
          document.getElementById("helpscreen")!.style.display = "none";
          document.getElementById("feedbackContent")!.focus();
        }
      });
    } else {
      GameState.game.ticks++;
      if (GameState.game.player)
        GameState.game.player.healingCounter++;

      if (actingActors.length > 0)
        App.console.updateStatus(GameState.game);
    }

    window.setTimeout(() => { GameState.game.advanceGame(); }, actingActors.length > 0 ? 500 : 0);
  }


  displayCursor() {
    let game = GameState.game;
    if (App.console.iteratorMode.iterator == undefined) {
      App.console.iteratorMode.iterator = 0;
    }

    if (App.console.iteratorMode.iterator < 0)
      App.console.iteratorMode.iterator += App.console.iteratorMode.relevantObjects.length;

    let focusedObject =
      App.console.iteratorMode.relevantObjects[
      App.console.iteratorMode.iterator % App.console.iteratorMode.relevantObjects.length
      ];

    App.console.context.fillStyle = "Yellow"
    TileRenderer.renderLetter(
      game,
      focusedObject.x,
      focusedObject.y,
      String.fromCharCode(0x25ce),
    );
  }


}


