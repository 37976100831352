import { Level } from "./Level"
import { MapGenerator } from "./MapGenerator"
import { GameState } from "./game"
import { App } from "./App"
import Item from "./GameObject"

export class Story {
  storySeed: string
  /**
   *Creates an instance of Story.
   * @memberof Story
   */

  constructor(game: GameState, storyTitle: string) {
    this.storySeed = storyTitle;
  }

  getMaxLevel() {
    return 8;
  }

  generateLevels() {
    const mg = new MapGenerator()
    let rv = [];
    let i: number;
    for (i = 1; i <= this.getMaxLevel(); i++) {
      let tags = [];
      if (i > 1) tags.push("stairsUp");
      if (i > 2) tags.push("abyss");
      if (i < this.getMaxLevel()) tags.push("stairsDown");
      const map = i === 1 ? mg.getDemoMap() : mg.generateMap(i, tags);

      rv[i] = new Level(i, Story.getLevelSeed(i), [], map);

      // ITEMS
      let itemDefs = App.itemDefs
      let ringDef = itemDefs[("RingOfStrength" as any)]
      // ring
      if (i == 1) {
        let item = new Item(8, 15, ringDef)
        rv[i].objects.push(item)
      }

    }
    return rv;
  }

  static getLevelSeed = function (level: number) {
    return "Apfel" + level.toString();
  };
}


