import { GameState } from "./game";
import { GameObject } from "./GameObject";

/**
 * Responsible for the console input and output, the status bar and addition debug information
 *
 * @export
 * @class Console
 */
export class App {
  static console: App
  static cursorTimeout: number
  static cursorTimer: number | undefined
  static keyStore: KeyboardEvent[] = []
  static keybindings: any
  static creatureDefs: any[] = []
  static itemDefs: any[] = []
  static raceDefs: any[] = []

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  iteratorMode: {
    active: boolean
    iterator: any
    relevantObjects: GameObject[]
    key: string
  }
  lastId: any;

  el: HTMLElement;
  output: HTMLElement;
  dbg: HTMLElement;
  status: HTMLElement;
  counter: number;
  interactive: "no" | "request" | "pick-one" | "pick-and-choose-one-auto" | "pick-and-choose-one-manually" | "pick-and-invest-manually" | "press-any-key"
  inputId: string = "";
  inputMinumumLength: number = 0;
  callback: Function = (a: string) => { }
  options: string = "";
  lastPress: string = "";
  values: string[] = [];

  /**
   *Creates an instance of Console.
   * @memberof Console
   */
  constructor() {
    this.canvas = document.getElementById("canvas") as HTMLCanvasElement;
    this.context = this.canvas.getContext("2d", { alpha: false })!;
    this.iteratorMode = { active: false, iterator: undefined, relevantObjects: [], key: '' }

    this.el = document.getElementById("console")!;
    this.output = document.getElementById("output")!;
    // this.input = document.getElementById("input");
    this.dbg = document.getElementById("debug")!;
    this.status = document.getElementsByClassName("status")[0] as HTMLElement;
    this.counter = 0;
    this.interactive = "no";
    var that = this;
    document.addEventListener("keydown", function (e) {
      that.receive(e);
    });

    setInterval(() => {
      this.el.scrollTop = this.el.scrollHeight;
    }, 400);

    App.console = that
    // this.hideInput();
  }

  outputProgramInfo() {
    this.add("&bull; press F1 to toggle handbook");
    this.add("&bull; press F8 to send a message to the developer");
    this.add("&bull; press F11 to toggle fullscreen mode");
  }

  outputGameInfo() {
    this.add("&bull; use WASD or cursor keys to move");
    this.add("&bull; use . to pause for one moment");
    this.add("&bull; use i to check your inventory");
    this.add("&bull; use g to get rid of stuff");
    this.add("&bull; use +/- to zoom in/out the map");
    this.add("&bull; see key hints at the lower right to interact");
  }

  receive(keyDownEvent: KeyboardEvent) {

    if (keyDownEvent.key === 'v' && keyDownEvent.ctrlKey === true) {
      if (navigator.clipboard.readText) {
        navigator.clipboard.readText()
          .then(text => {
            if (text.length > 0) {
              var el = document.getElementById(this.inputId)!
              el.innerText = el.innerText.substr(0, this.inputMinumumLength) + " " + text.trim()
            }
          })
          .catch(err => {
            // maybe user didn't grant access to read from clipboard
            console.log('Something went whith clipboard.readText', err);
          });
      }
      keyDownEvent.preventDefault();
      return
    }


    if (document.getElementById("feedbackscreen")!.style.display != "none")
      return;


    if (keyDownEvent.type === "keydown") {

      // handle backspace;
      switch (keyDownEvent.key) {
        case "Escape":
          if (this.interactive !== "no") {
            this.interactive = "no";
            this.removeTemporary();
          }
          break;
        case "Backspace":
          var el = document.getElementById(this.inputId);
          if (el) {
            let textSoFar = el.innerHTML;
            if (textSoFar.length > this.inputMinumumLength) {
              el.innerText = textSoFar.substr(0, el.innerText.length - 1);
            }
          }
          return
          break;

        default:
      }
      //return;
    }

    if (keyDownEvent.key.length > 1
      && keyDownEvent.key !== "Enter"
      && keyDownEvent.key != "ArrowUp"
      && keyDownEvent.key != "ArrowDown"
      && keyDownEvent.key != "ArrowLeft"
      && keyDownEvent.key != "ArrowRight"
      && keyDownEvent.key != "PageUp"
      && keyDownEvent.key != "PageDown") {
      // Shift, ...
      return
    }
    switch (this.interactive) {
      case "request": {
        var inputEl = document.getElementById(this.inputId)!;
        let textSoFar = inputEl.innerHTML;
        if (keyDownEvent.key == "Enter") {
          this.interactive = "no";
          this.removeTemporary();
          let cb = this.callback;
          this.callback = () => { }
          cb(inputEl.innerText.substr(this.inputMinumumLength));
          return;
        }
        inputEl.innerText = textSoFar + keyDownEvent.key;
        break;
      }
      case "pick-one":
        if (this.options.indexOf(keyDownEvent.key) != -1) {
          this.removeTemporary();
          this.interactive = "no";
          this.callback(keyDownEvent.key);
          return;
        }
        break;
      case "press-any-key":
        this.removeTemporary()
        this.interactive = "no"
        this.callback()
        return;
        break;
      case "pick-and-choose-one-auto":
        if (this.options.indexOf(keyDownEvent.key) != -1) {
          if (keyDownEvent.key == this.lastPress) {
            this.removeTemporary();
            this.interactive = "no";
            this.callback(this.values[this.options.indexOf(keyDownEvent.key)]);
            return;
          } else {
            this.hideTooltips(keyDownEvent.key);
            this.lastPress = keyDownEvent.key;
            return;
          }
        }
        break;
      case "pick-and-choose-one-manually":
        if (this.options.indexOf(keyDownEvent.key) != -1) {
          if (keyDownEvent.key == this.lastPress) {
            this.removeTemporary();
            this.interactive = "no";
            this.callback(keyDownEvent.key);
            return;
          } else {
            this.hideTooltips(keyDownEvent.key);
            this.lastPress = keyDownEvent.key;
            return;
          }
        }
        break;
      case "pick-and-invest-manually":
        if (this.options.indexOf(keyDownEvent.key) != -1) {
          if (keyDownEvent.key == this.lastPress) {
            return;
          } else {
            this.hideTooltips(keyDownEvent.key);
            this.lastPress = keyDownEvent.key;
            return;
          }
        }
        break;
    }

    if (GameState.game) {
      App.keyStore.push(keyDownEvent);
      if (App.keyStore.length > 0) {
        GameState.game.advanceGame();
      }
    }
  }

  /**
   * Adds text to the console, including a line break.
   *
   * @param {*} text The text to be added to the console.
   * @memberof Console
   */
  add(text: string, temporary?: boolean) {
    if (text.length === 0)
      return
    var newEl = document.createElement("div");
    if (temporary) newEl.id = "ID" + this.counter++;
    newEl.innerHTML = text;
    this.output.appendChild(newEl);
    if (!temporary) return;
    return newEl.id;
  }

  get content() {
    return this.output.innerHTML;
  }

  set content(value) {
    this.output.innerHTML = value;
  }

  addLine(temporary: boolean = false) {
    var newEl = document.createElement("div");
    if (temporary) newEl.id = "ID" + this.counter++;
    newEl.style.borderBottom = "0.3ex white dotted";
    newEl.style.marginTop = "5px";
    newEl.style.marginBottom = "5px";
    newEl.innerHTML = "";
    this.output.appendChild(newEl);
    if (!temporary) return;
    return newEl.id;
  }

  addTooltip(value: string, info: string) {
    var newEl = document.createElement("div");
    newEl.id = "ID" + value;
    newEl.classList.add("tooltip");
    newEl.innerHTML = info;
    newEl.style.display = "none";
    this.output.appendChild(newEl);
  }

  hideTooltips(exception: string) {
    let els = document.getElementsByClassName("tooltip");
    let i = 0;
    for (i = 0; i < els.length; i++) {
      (els[i] as HTMLElement).style.display = "none";
    }

    if (exception) {
      let el = document.getElementById("ID" + exception);
      if (el) {
        el.style.display = "block";
      }
    }
  }

  remove(id: string) {
    var element = document.getElementById(id)!;
    if (element instanceof HTMLElement) {
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    }
  }

  removeTemporary() {
    let elements = document.getElementsByTagName("div");
    for (let i = 0; i < elements.length; i++) {
      let el = elements[i];
      if (el.id.startsWith("ID")) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
          i = -1;
        }
      }
    }
  }

  debug(text: string) {
    this.dbg.innerHTML = text;
  }

  /*hideInput() {
    this.input.style.display = "none";
  }*/

  resetChangeIndicators() {
    let values = document
      .getElementById("status")!
      .getElementsByClassName("value");
    for (let i = 0; i < values.length; i++) {
      values[i].classList.remove("changed");
    }
  }

  updateStatus(game: GameState) {
    if (!game.player)
      return

    this.setStatus("Character<br>Level", game.player.level, false);
    this.setStatus(
      "Hit<br>Points",
      game.player.hitpoints + "/" + game.player.maxHitpoints,
      false
    );
    this.setStatus("Strength", game.player.actualStrength, false);

    this.setStatus("Speed", 16 - game.player.speed, false);
    this.setStatus("Ticks", game.ticks, true);
    this.setStatus("Gold", game.player.gold, false);
    this.setStatus("Dungeon<br/>Level", game.currentLevelIndex, false);

    this.setStatus("Healing<br>Counter", game.player.healingCounter, true);
    this.setStatus("Attack<br>Value", game.player.attackValue, false);
    this.setStatus("Defense<br>Value", game.player.defenseValue, false);
  }

  createId(label: string): string {
    return label.replace(" ", "");
  }
  setStatus(label: string, value: number | string, debug: boolean) {
    if (window.location.hostname.indexOf("localhost") == -1 && debug) {
      return;
    }
    let id = "ID" + this.createId(label);
    let el = document.getElementById(id);
    if (!el) {
      el = document.createElement("span");
      el.id = id;
      el.classList.add("statusField");
      if (debug) el.classList.add("debug");
      this.status.appendChild(el);
    }

    let modifiers = ["Strength"];
    let cssClass = "";

    let oldValue = value;
    let elValue = undefined;
    for (let i = 0; i < el.childNodes.length; i++) {
      let child = el.childNodes[i] as HTMLElement; // TODO Can this made be better?
      if (child.classList) {
        if (child.classList.length > 0) {
          if (child.classList[0] == "value") {
            elValue = child;
            cssClass = child.classList[0] // TODO This was a bug all along?
            oldValue = child.innerHTML;
            i = el.childNodes.length;
          }
        }
      }
    }

    if (oldValue != value && !debug && elValue) {
      elValue.classList.add("changed");
    }
    if (modifiers.includes(label)) {
      el.innerHTML =
        label +
        "<br/><span class='value " +
        cssClass +
        "'>" +
        (value <= 0 ? "" : "+") +
        value +
        "</span>";
    } else {
      el.innerHTML =
        label +
        "<br/><span class='value " +
        cssClass +
        "'>" +
        value +
        "</span>";
    }
  }

  request(message: string, callback: Function) {
    this.interactive = "request";
    //    this.add(message, false);
    this.inputId = this.add(message, true)!;
    this.callback = callback;
    this.inputMinumumLength = message.length;
  }

  pickOne(options: any, callback: Function) {
    this.interactive = "pick-one"
    this.options = options
    this.callback = callback
  }

  pressAnyKey(message: string, callback: Function) {
    this.interactive = "press-any-key"
    this.add(message + "<br/>(Press any key to continue)")
    this.callback = callback
  }

  pickAndChooseOneAuto(values: string[], infos: string[], callback: Function) {
    this.lastPress = "jiojio";
    this.interactive = "pick-and-choose-one-auto";
    this.options = "";
    this.values = values;
    let littleA = 97;
    values.forEach((el, index) => {
      let c = String.fromCharCode(littleA);
      this.options += c;
      this.add(c + ") " + el, true);
      littleA++;
      this.addTooltip(c, infos[index]);
    });

    this.callback = callback;
  }

  pickAndChooseOneManual(characters: any[], values: string[], infos: string[], callback: Function) {
    this.lastPress = "jiojio";
    this.interactive = "pick-and-choose-one-manually";
    this.options = "";
    this.values = values;
    values.forEach((el, index) => {
      let c = characters[index];
      this.options += c;
      this.add(c + ") " + el, true);
      this.addTooltip(c, infos[index]);
    });
    this.callback = callback;
  }

  pickAndInvestManual(characters: any[], values: string[], infos: string[], callback: Function = (a: string) => { }) {
    this.lastPress = "Bretzel";
    this.interactive = "pick-and-invest-manually";
    this.options = "";
    this.values = values;
    values.forEach((el: any, index: number) => {
      let c: string = characters[index];
      this.options += c;
      this.add(c + ") " + el, true);
      this.addTooltip(c, infos[index]);
    });
    this.callback = callback;
  }

  static checkLicense(application: string, key: string, callback: Function = (a: number) => { }): void {
    if (!navigator.onLine) {
      callback(0);
      return
    }

    let xhr = new XMLHttpRequest();
    //xhr.withCredentials = false;
    let data = new FormData();
    data.append("task", "checklicense");
    data.append("app", application);
    data.append("key", key);
    xhr.open("POST", "https://aitazan.osrandil.de/gameservices.php", true);
    xhr.onload = function (e) {
      const ee = parseInt(xhr.response, 10);
      switch (ee) {
        case 1:
        case 2:
          localStorage.setItem("license", "invalid");
          break;
        case 3:
          localStorage.setItem("license", "valid");
          break;
        default:
        // change nothing
      }
      callback(ee);
    };

    xhr.onerror = function (e) {
      console.log("error", e);
      callback(0);
    };
    xhr.setRequestHeader("Accept", "text/plain");
    xhr.send(data);
  };

  static isKey(key: string | number, config: string | string[]): boolean | number {
    if (typeof key === 'number' && typeof config === 'number') {
      return key == config
    }

    switch (typeof config) {
      case 'string':
      case 'object':
        return config.includes(<string>key)
      case 'number':
        return key == config
    }
  };
}
