
import { App } from "./App"
import { GameState } from "./game";
import FontFaceObserver from "fontfaceobserver";
import FontMetrics from "fontmetrics";
import TileRenderer from "./Renderer";
import MarkdownIt from "markdown-it";
import { Story } from "./Story"
import { Level } from "./Level";
import { Map, MapTile, MapGenerator } from "./MapGenerator";

import tomlraces from './races.toml'
import tomlkeybindings from './keybindings.toml'
import tomlitems from './items.toml'
import tomlcreatures from './creatures.toml'
import { GameObject, Actor, Player, Sign } from "./GameObject";

App.cursorTimeout = 10 * 1000;
App.cursorTimer = undefined;
window.addEventListener("mousemove", () => {
  document.body.style.cursor = "initial";
  if (App.cursorTimer != null) {
    clearTimeout(App.cursorTimer);
  }
  App.cursorTimer = setTimeout(() => {
    document.body.style.cursor = "none";
  }, App.cursorTimeout);
});


function initGame() {
  let game: GameState = new GameState();
  let dummy: TileRenderer = new TileRenderer()
  console.dir(App.raceDefs)
  GameState.game.raceDefs = App.raceDefs
  GameState.game.creatureDefs = App.creatureDefs
  GameState.game.itemDefs = App.itemDefs


  TileRenderer.fontFactor = 1.18;
  const metrics = FontMetrics({
    fontFamily: "Arial",
    // Optional (defaults)
    fontWeight: "normal",
    fontSize: 1000,
    origin: "baseline"
  });
  TileRenderer.fontFactor = Math.abs(metrics.capHeight) + Math.abs(metrics.bottom);
  console.log("fontFactor", TileRenderer.fontFactor)
  GameState.game = game;

  GameState.story = new Story(game, "Borstel");
  game.levels = GameState.story.generateLevels();
  game.currentLevelIndex = 1;

  let emptyTile = game.currentLevel.getEmptyTile();
  while (emptyTile == null) {
    console.log("No place for the player, redo level");
    const mg = new MapGenerator()
    const map: Map = mg.generateMap(
      game.currentLevelIndex,
      game.currentLevel.tags
    );
    game.levels[game.currentLevelIndex] = new Level(
      game.currentLevelIndex,
      Story.getLevelSeed(game.currentLevelIndex),
      [],
      map
    );
    emptyTile = game.currentLevel.getEmptyTile();
  }

  let player = new Player(8, 12);
  game.currentLevel.objects.push(player);
  let sign = new Sign(10, 11, "Welcome to Aitazan")
  game.currentLevel.objects.push(sign)
}

var font = new FontFaceObserver("DungeonFont");

font.load(null, 7000).then(function () {

  var l = navigator.languages
    ? navigator.languages[0]
    : navigator.language
  if (l.startsWith("de")) {
    document.getElementById("de")!.style.display = "initial";
    document.getElementById("en")!.style.display = "none";
  }

  // Canvas kontaktieren


  var resizeTimer: number | undefined;
  document.body.onresize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      App.console.canvas.style.display = "unset";
      var el = document.getElementById("map")!;

      let dpr = window.devicePixelRatio || 1;

      App.console.canvas.width = el.clientWidth * dpr - 1;
      App.console.canvas.height = el.clientHeight * dpr;

      //GameState.context.scale(dpr,dpr);
      TileRenderer.center = true;
      if (GameState.game) {
        TileRenderer.renderLevel(GameState.game);
        TileRenderer.renderLevel(GameState.game);
      }
      TileRenderer.center = false;
    }, 150);


  };




  function onKeyDown(event: KeyboardEvent) {
    if (
      App.isKey(
        event.keyCode,
        App.keybindings.meta.toggleFeedbackDialog
      )
    ) {
      event.preventDefault();
      if (document.getElementById("feedbackscreen")!.style.display != "none") {
        document.getElementById("feedbackscreen")!.style.display = "none";
        return;
      } else {
        document.getElementById("feedbackscreen")!.style.display = "initial";
        document.getElementById("helpscreen")!.style.display = "none";
        document.getElementById("feedbackContent")!.focus();
        return;
      }
    }
    if (
      App.isKey(
        event.keyCode,
        App.keybindings.meta.toggleHelpScreen
      )
    ) {
      event.preventDefault();
      if (document.getElementById("helpscreen")!.style.display != "none") {
        document.getElementById("helpscreen")!.style.display = "none";
        return;
      } else {
        document.getElementById("helpscreen")!.style.display = "initial";
        document.getElementById("feedbackscreen")!.style.display = "none";
        document.getElementById("helpscroll")!.focus();
        return;
      }
    }

    if (App.isKey(event.key, App.keybindings.meta.zoomIn)) {
      if (TileRenderer.tileSize < 50) {
        TileRenderer.tileSize += 1;
        const metrics = FontMetrics({
          fontFamily: "Arial",
          // Optional (defaults)
          fontWeight: "normal",
          fontSize: TileRenderer.tileSize,
          origin: "baseline",
        });
        TileRenderer.fontFactor =
          Math.abs(metrics.capHeight) + Math.abs(metrics.bottom);
        TileRenderer.renderLevel(GameState.game)
      }
      return
    } else if (App.isKey(event.key, App.keybindings.meta.zoomOut)) {
      if (TileRenderer.tileSize > 14) {
        TileRenderer.tileSize -= 1;
        const metrics = FontMetrics({
          fontFamily: "Arial",
          // Optional (defaults)
          fontWeight: "normal",
          fontSize: TileRenderer.tileSize,
          origin: "baseline"
        });
        TileRenderer.fontFactor =
          Math.abs(metrics.capHeight) + Math.abs(metrics.bottom);
        TileRenderer.renderLevel(GameState.game)
      }
      return
    }
  }

  let _dummy = new App()

  // Load stuff from TOML
  App.keybindings = tomlkeybindings;

  let license = window.localStorage["license"]

  let special = (tomlstuff: any, defs: any[], what: string) => {
    let anyDefs = Object.entries(tomlstuff)
    anyDefs.forEach((a: any) => {
      if (typeof a[1] === "object") {
        for (let i = 0; i < a[1].tags.length; i++) {
          a[1].tags[i] = a[1].tags[i].toLowerCase();
        }
        defs[a[0]] = a[1]
      }
    });
    if (license !== "valid") {
      let before = Object.keys(defs).length
      let reduced = Math.max(1, Math.floor(before / 2))
      App.console.add(reduced + " of " + before + " " + what);
      while (Object.keys(defs).length > reduced) {
        let lastKey: any = Object.keys(defs).pop()!
        delete defs[lastKey]
      }
    } else {
      App.console.add(defs.length + " " + what);
    }
  }

  special(tomlraces, App.raceDefs, "classes")
  special(tomlcreatures, App.creatureDefs, "creatures")

  let items = (tag: string, label: string) => {
    let anyDefs = Object.entries(tomlitems)
    anyDefs = anyDefs.filter((ddd: any) => {
      return GameState.hasTag(ddd[1], tag)[0];
    })
    anyDefs.forEach((a: any) => {
      if (typeof a[1] === "object") {
        for (let i = 0; i < a[1].tags.length; i++) {
          a[1].tags[i] = a[1].tags[i].toLowerCase();
        }
        App.itemDefs[a[0]] = a[1]
      }
    });
    if (license !== "valid") {
      let before = Object.keys(anyDefs).length
      let reduced = Math.max(1, Math.floor(before / 2))
      App.console.add(reduced + " of " + before + " " + label);
      while (Object.keys(anyDefs).length > reduced) {
        let lastKey: any = Object.keys(anyDefs).pop()!
        delete App.itemDefs[lastKey]
        anyDefs = App.itemDefs.filter((ddd: any) => {
          return GameState.hasTag(ddd[1], tag)[0];
        })
      }
    } else {
      App.console.add(anyDefs.length + " " + label);
    }
  }

  items("ring", "rings")
  items("melee", "melee weapons");

  items("armor", "armor");

  items("shield", "shields");

  items("potion", "magic potions");

  if (license !== "valid") {
    App.console.add(`
      For full content, consider to <a style="color: #efe0b9; font-weight: bold;" class="gXXumroad-button" href="https://gum.co/aitazan" target="_blank"
    data-gumroad-single-product="true" >purchase a license</a>
      `, false)
  }

  App.console.addLine();
  App.console.outputProgramInfo();
  //document.onkeydown = onKeyDown;
  document.addEventListener("keydown", onKeyDown)


  let key = window.localStorage["licenseKey"]
  if (key != undefined) {
    App.checkLicense(
      "SjVlnL",
      key,
      function (e: number) {
        console.log("license state", e);
        // run();
      }
    );
  }
  run();
});


declare let window: any

function run() {

  // Translate the help document from Markdown to HTML
  let url = "./handbook.md";
  fetch(url).then(function (response) {
    response.text().then(function (text) {
      let el = document.getElementById("helpscroll")!;
      el.innerHTML = new MarkdownIt("default", {
        html: false,
        xhtmlOut: true,
        typographer: false,
        linkify: false,
        breaks: false
      }).render(text);
    });
  });
  // End of Translation


  window.sessionStorage.clear();
  App.console.add("What do you want to do?", true);
  let c = 97;
  let options = "";
  let licenseLetter = ''
  let resetLetter = ''
  let loadLetter = ''
  App.console.add(String.fromCharCode(c) + ") Start game", true);
  options += String.fromCharCode(c);
  c++;
  if (window.localStorage["savedGame"] != undefined) {
    loadLetter = String.fromCharCode(c)
    App.console.add(loadLetter + ") Load game", true);
    options += loadLetter
    c++;
  }

  let license = window.localStorage["license"]
  if (license === undefined || license === "invalid") {
    licenseLetter = String.fromCharCode(c)
    App.console.add("<b>" + licenseLetter + ") Enter license key</b>", true)
    options += licenseLetter
    c++
  }
  if (window.location.hostname.indexOf("localhost") != -1) {
    resetLetter = String.fromCharCode(c)
    App.console.add(resetLetter + ") Reset", true);
    options += resetLetter
  }
  App.console.pickOne(options, (b: any) => {
    if (b == options[0]) {
    } else {
      if (b === resetLetter) {
        window.localStorage.clear();
        window.location.reload();
        return;
      }
      if (b === loadLetter) {
        // Load saved game
        // https://stackoverflow.com/questions/40201589/serializing-an-es6-class-object-as-json
        let json = window.localStorage.getItem("savedGame");

        let game: GameState = GameState.load(json);
        App.console.outputGameInfo();
        App.console.updateStatus(game);
        game.currentLevel.makeViewMap(game.player!);
        TileRenderer.center = true;
        TileRenderer.renderLevel(game);
        TileRenderer.renderLevel(game);
        TileRenderer.center = false;
        console.log("calling after loading a saved game")
        if (document.body.onresize !== null) {
          document.body.onresize(new UIEvent("dummy"))
        }
        GameState.game?.advanceGame();
        return;
      }
      if (b === licenseLetter) {
        App.console.request("Please enter your Gumroad license key: ",
          (a: any) => {
            window.localStorage["licenseKey"] = a
            App.checkLicense(
              "SjVlnL",
              a,
              function (e: number) {
                if (e === 3) {
                  App.console.pressAnyKey("License key accepted", () => { window.location.reload() });
                } else {
                  App.console.pressAnyKey("License key <b>not</b> accepted", () => { window.location.reload() });
                }
                console.log("new license state", e);
                return
              }
            );
          })
      }
      return
    }

    let playerName = window.localStorage.getItem("playerName") || "Adventurer";
    let playerGender = ""
    App.console.request(
      "What is your name (" + playerName + ")? ",
      (a: any) => {
        if (a.length == 0) a = playerName
        playerName = a;
        window.localStorage.setItem("playerName", playerName);
        App.console.removeTemporary();
        App.console.add("What is your gender?", true);
        App.console.add("a) male", true);
        App.console.add("b) female", true);
        App.console.add("c) diverse", true);
        App.console.pickOne("abc", (b: any) => {
          switch (b) {
            case 'a':
              playerGender = "male"
              break;
            case 'b':
              playerGender = "female"
              break;
            case 'c':
              playerGender = "diverse"
          }

          let values: any[] = [];
          let infos: any[] = [];
          Object.keys(App.raceDefs).forEach(function (key: any) {
            let e = App.raceDefs[key];
            if (typeof e === "object") {
              if (
                !GameState.hasTag(e, "maleonly")[0] ||
                playerGender == "male"
              ) {
                if (
                  !GameState.hasTag(e, "femaleonly")[0] ||
                  playerGender == "female"
                ) {
                  if (!GameState.hasTag(e, "diverseonly")[0] ||
                    playerGender == "diverse") {
                    if (e.name && e.description) {
                      values.push(e.name);
                      infos.push(e.description);
                    }
                  }
                }
              }
            }
          });
          App.console.add("What is your class? Choose and confirm.", true);

          App.console.pickAndChooseOneAuto(values, infos, (b: any) => {

            let race = (<any>Object).values(App.raceDefs)
              .filter((a: any) => {
                return a.name != undefined;
              })
              .find((a: any) => {
                return a.name == b;
              });

            // Finally setup the game!
            initGame()

            GameState.game.player.name = playerName
            GameState.game.player.applyRace(race);
            App.console.outputGameInfo();
            App.console.updateStatus(GameState.game);
            GameState.game.currentLevel.makeViewMap(GameState.game.player!);
            TileRenderer.center = true;
            TileRenderer.renderLevel(GameState.game);
            TileRenderer.renderLevel(GameState.game);
            TileRenderer.center = false;

            if (document.body.onresize !== null) {
              document.body.onresize(new UIEvent("dummy"))
            }
            App.keyStore = []
            GameState.game?.advanceGame();
          });
        });
      }
    );
  });
}

