import { Level } from "./Level";
import { GameState } from "./game";
import { MapTileType, MapTileDef } from "./MapTiles"

export type MapTile = { type: MapTileType, area?: number, item?: any }
export type Map = { width: number, height: number, tiles: MapTile[] }

export class MapGenerator {
  private internalMap: Map = { width: 1, height: 1, tiles: [] }

  private putPixel(x: number, y: number, c: MapTileType): void {
    let index = y * this.internalMap.width + x;
    this.internalMap.tiles[index].type = c;
  };

  private getPixel(x: number, y: number): MapTileType {
    let index = y * this.internalMap.width + x;
    return this.internalMap.tiles[index].type;
  };

  private drawHLine(x1: number, x2: number, y: number, c: MapTileType): void {
    for (let i = x1; i <= x2; i++)
      this.putPixel(i, y, c)
  }
  private drawVLine(x: number, y1: number, y2: number, c: MapTileType): void {
    for (let i = y1; i <= y2; i++)
      this.putPixel(x, i, c)
  }

  drawRectangle(x1: number, y1: number, x2: number, y2: number, c: MapTileType) {
    for (let x = x1; x <= x2; x++)
      this.drawVLine(x, y1, y2, c)
  }


  private floodfill(x: number, y: number, color: MapTileType): void {
    x = Math.floor(x);
    y = Math.floor(y);
    const oriColor = this.internalMap.tiles[this.internalMap.width * y + x].type;

    const fillColor = color;

    let innerFill = (x: number, y: number, oriColor: any, fillColor: any) => {
      const thisColor = this.internalMap.tiles[this.internalMap.width * y + x].type;
      if (thisColor == oriColor) {
        this.putPixel(x, y, fillColor);
        if (x > 0) innerFill(x - 1, y, oriColor, fillColor);
        if (x < this.internalMap.width - 1) innerFill(x + 1, y, oriColor, fillColor);
        if (y > 0) innerFill(x, y - 1, oriColor, fillColor);
        if (y < this.internalMap.height - 1) innerFill(x, y + 1, oriColor, fillColor);
      }
    };
    innerFill(x, y, oriColor, fillColor);
  }

  public getDemoMap(): Map {
    this.internalMap.tiles = []
    this.internalMap.width = 58
    this.internalMap.height = 38
    this.internalMap.tiles = []
    // CaveFloor
    for (let i = 0; i < this.internalMap.width * this.internalMap.height; i++) {
      this.internalMap.tiles[i] = { type: MapTileType.CaveFloor }
    }

    // CaveWall
    this.drawHLine(0, this.internalMap.width - 1, 0, MapTileType.CaveWall)
    this.drawHLine(0, this.internalMap.width - 1, this.internalMap.height - 1, MapTileType.CaveWall)
    this.drawVLine(0, 0, 6, MapTileType.CaveWall)
    this.drawVLine(this.internalMap.width - 1, 0, this.internalMap.height - 1, MapTileType.CaveWall)

    this.putPixel(54, 36, MapTileType.CaveWall)
    this.drawVLine(55, 35, 36, MapTileType.CaveWall)
    this.drawVLine(56, 32, 36, MapTileType.CaveWall)

    // Abyss
    this.drawVLine(0, 7, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(1, 11, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(2, 15, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(3, 21, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(4, 22, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(5, 26, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(6, 32, this.internalMap.height - 1, MapTileType.Abyss)
    this.drawVLine(7, 34, this.internalMap.height - 1, MapTileType.Abyss)

    // CityFloor
    this.drawRectangle(13, 5, 23, 18, MapTileType.CityFloor)

    // CityWall
    this.drawHLine(15, 21, 6, MapTileType.CityWall)
    this.drawVLine(15, 7, 13, MapTileType.CityWall)
    this.drawVLine(21, 7, 13, MapTileType.CityWall)
    this.putPixel(16, 13, MapTileType.CityWall)
    this.putPixel(20, 13, MapTileType.CityWall)

    // Pillar
    this.putPixel(15, 15, MapTileType.Pillar)
    this.putPixel(17, 15, MapTileType.Pillar)
    this.putPixel(19, 15, MapTileType.Pillar)
    this.putPixel(21, 15, MapTileType.Pillar)

    // Water
    this.drawHLine(6, 15, 26, MapTileType.Water)
    this.drawHLine(5, 21, 25, MapTileType.Water)
    this.drawHLine(15, 24, 24, MapTileType.Water)
    this.drawHLine(21, 25, 23, MapTileType.Water)
    this.drawHLine(24, 36, 22, MapTileType.Water)
    this.drawHLine(26, 40, 21, MapTileType.Water)
    this.drawHLine(36, 45, 20, MapTileType.Water)
    this.drawHLine(40, 47, 19, MapTileType.Water)
    this.drawHLine(44, 49, 18, MapTileType.Water)
    this.drawHLine(47, 54, 17, MapTileType.Water)
    this.drawHLine(49, 54, 16, MapTileType.Water)
    this.drawHLine(51, 54, 15, MapTileType.Water)
    this.drawHLine(53, 54, 14, MapTileType.Water)
    this.drawRectangle(55, 14, 56, 18, MapTileType.Water)
    return this.internalMap
  }

  public generateMap(size: number, tags: string[]): Map {
    this.internalMap.tiles = []
    let dim = this.internalMap.width = this.internalMap.height = Math.round(Math.sqrt(size * 100));
    for (let i = 0; i < dim * dim; i++) {
      this.internalMap.tiles[i] = { type: MapTileType.Undefined }
    }

    type Seed = [number, number, MapTileType]
    let seeds: Seed[] = []

    let putSeedAndPixel = (x: number, y: number, c: MapTileType) => {
      this.putPixel(x, y, c);
      let seed: Seed = [x, y, c];
      seeds.push(seed);
    }

    const outerStepMax = 6;
    const outerStepPlus = 0;
    const outerStepSum = outerStepMax + outerStepPlus;
    // Generate seeds
    for (let i = 0; i < Math.max(size, 2); i++) {
      let x = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      let y = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      let c = GameState.throwDie(3) - 1;
      let t = c == 0 ? MapTileType.CaveWall : MapTileType.CaveFloor
      putSeedAndPixel(x, y, t);
    }

    for (let i = 0; i < dim; i += GameState.throwDie(outerStepMax) + outerStepPlus) {
      putSeedAndPixel(i, 0, MapTileType.CaveWall);
    }
    for (let i = 0; i < dim; i += GameState.throwDie(outerStepMax) + outerStepPlus) {
      putSeedAndPixel(0, i, MapTileType.CaveWall);
    }
    for (let i = 0; i < dim; i += GameState.throwDie(outerStepMax) + outerStepPlus) {
      putSeedAndPixel(dim - 1, i, MapTileType.CaveWall);
    }
    for (let i = 0; i < dim; i += GameState.throwDie(outerStepMax) + outerStepPlus) {
      putSeedAndPixel(i, dim - 1, MapTileType.CaveWall);
    }

    // Water
    if (tags.includes("water")) {
      if (GameState.throwDie(1) == 1) {
        do {
          let x = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
          let y = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
          putSeedAndPixel(x, y, MapTileType.Water);
        } while (GameState.throwDie(2) == 1);
      }
    }

    // Abyss
    if (tags.includes("abyss")) {
      let x0 = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      let y0 = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      let x1 = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      let y1 = GameState.throwDie(dim - outerStepMax * 2) - 1 + outerStepSum;
      //let x1 = x0 < dim / 2 ? 0 : dim - 1;
      //let y1 = y0 < dim / 2 ? 0 : dim - 1;
      const coords = Level.getLineCoords(x0, y0, x1, y1);
      putSeedAndPixel(x0, y0, MapTileType.Abyss);
      putSeedAndPixel(x1, y1, MapTileType.Abyss);
      coords.forEach(coord => {
        putSeedAndPixel(coord.x, coord.y, MapTileType.Abyss);
      });

      if (x1 == 0) putSeedAndPixel(x1 + 1, y1, MapTileType.Abyss);
      else putSeedAndPixel(x1 - 1, y1, MapTileType.Abyss);
      if (y1 == 0) putSeedAndPixel(x1, y1 + 1, MapTileType.Abyss);
      else putSeedAndPixel(x1, y1 - 1, MapTileType.Abyss);
    }

    // find closest for all transparent Pixels!!!!
    for (let y = 0; y < dim; y++) {
      for (let x = 0; x < dim; x++) {
        let a = this.internalMap.tiles[y * dim + x];
        if (a.type != MapTileType.Undefined) continue;

        let distance = function (x: number, y: number, seed: any) {
          let diffx = seed[0] - x;
          let diffy = seed[1] - y;
          return diffx * diffx + diffy * diffy;
        };
        /*
        seeds.sort(function(a, b) {
          let ar = distance(x, y, a);
          let br = distance(x, y, b);
          return br - ar;
        });*/
        let dist = Number.MAX_VALUE;
        let c: MapTileType = MapTileType.Undefined
        seeds.forEach(seed => {
          let curDist = distance(x, y, seed);
          if (curDist < dist) {
            dist = curDist;
            c = seed[2];
          }
        });
        this.putPixel(x, y, c);
      }
    }



    // Eliminate water next to an abyss
    for (let y = 0; y < dim - 2; y++) {
      for (let x = 0; x < dim - 2; x++) {
        let water = false;
        let abyss = false;
        let coords = [[0, 0], [1, 0], [0, 1], [1, 1]];
        let foundAt: { x: number, y: number }
        coords.forEach(coord => {
          let thisColor = this.internalMap.tiles[(y + coord[1]) * dim + (x + coord[0])].type;
          if (thisColor === MapTileType.Water) {
            water = true;
            foundAt = { x: x + coord[0], y: y + coord[1] }
          }
          if (thisColor === MapTileType.Abyss) {
            abyss = true;
          }
          if (abyss && water === true) {
            water = false;
            this.floodfill(foundAt.x, foundAt.y, MapTileType.CaveFloor);
            y = -1;
            x = -1;
          }
        });
      }
    }

    // Place ruin 4-floor, 5-wall
    if (tags.includes("ruins")) {
      let margin = 10;
      let cx = GameState.throwDie(dim - margin * 2) - 1 + margin;
      let cy = GameState.throwDie(dim - margin * 2) - 1 + margin;
      let width = GameState.throwDie(4) + 4;
      let height = GameState.throwDie(4) + 4;

      let count = 0;
      if (this.getPixel(cx, cy) == MapTileType.CaveWall) count++;
      if (this.getPixel(cx - width, cy - height) === MapTileType.CaveWall) count++;
      if (this.getPixel(cx + width, cy - height) === MapTileType.CaveWall) count++;
      if (this.getPixel(cx - width, cy + height) === MapTileType.CaveWall) count++;
      if (this.getPixel(cx + width, cy + height) === MapTileType.CaveWall) count++;

      if (count >= 3) {
        count = 0;
        //putSeedAndPixel(cx, cy, 4);
        for (let y = cy - height; y < cy + height; y++) {
          for (let x = cx - width; x < cx + width; x++) {
            if (this.getPixel(x, y) === MapTileType.CaveWall) {
              this.putPixel(x, y, MapTileType.RuinFloor);
              count++;
            }
          }
        }
        if (count > 10) {
          for (let ws = 0; ws < Math.min(width, height) * 2; ws++) {
            // Place ruined walls
            let wx = cx + GameState.throwDie(width) - GameState.throwDie(width);
            let wy = cy + GameState.throwDie(height) - GameState.throwDie(height);
            let dir1 = GameState.throwDie(2);
            let dir2 = GameState.throwDie(2);
            if (dir1 == 1) {
              // horizontal
              let length = 4 + GameState.throwDie(width);
              if (dir2 == 2) wx = wx - length;
              // draw horizontal line
              for (let l = wx; l < wx + length; l++) {
                if (this.getPixel(l, wy) === MapTileType.RuinFloor)
                  this.putPixel(l, wy, MapTileType.RuinWall);
              }
            } else {
              // vertical
              let length = GameState.throwDie(height);
              if (dir2 == 2) wy = wy - length;
              // draw vertical line
              for (let l = wy; l < wy + length; l++) {
                if (this.getPixel(wx, l) === MapTileType.RuinFloor)
                  this.putPixel(wx, l, MapTileType.RuinWall);
              }
            }
          }
        }
        // add rubble
        let number = (width * height) / 10;
        for (let r = 0; r < number; r++) {
          let rx = cx - width + GameState.throwDie(width * 2);
          let ry = cy - height + GameState.throwDie(height * 2);
          if (this.getPixel(rx, ry) === MapTileType.CityFloor) this.putPixel(rx, ry, MapTileType.RuinWall);
        }
      }
    }

    // Find areas and their sizes
    let floodfillArea = (x: number, y: number, fillArea: any) => {
      x = Math.floor(x);
      y = Math.floor(y);

      let innerFill = (x: number, y: number, fillArea: any) => {
        let rv = 0;
        const thisType = this.internalMap.tiles[dim * y + x].type;
        const thisArea = this.internalMap.tiles[dim * y + x].area;
        if (thisArea != undefined) {
          return 0;
        }
        if (thisType !== MapTileType.Undefined) {
          if (MapTileDef.get(thisType)?.blocksPath) {
            // add other floors and water. No! Use passable attribute
            this.internalMap.tiles[x + y * dim].area = fillArea;
            rv = 1;
            if (x > 0) rv += innerFill(x - 1, y, fillArea);
            if (x < dim - 1) rv += innerFill(x + 1, y, fillArea);
            if (y > 0) rv += innerFill(x, y - 1, fillArea);
            if (y < dim - 1) rv += innerFill(x, y + 1, fillArea);
          }
        }
        return rv;
      };
      return innerFill(x, y, fillArea);
    }

    let areaCounter = 1;
    let largestArea = 0;
    let largestAreaCounter = 0;
    for (let i = 0; i < dim * dim; i++) {
      let cx = i % dim;
      let cy = Math.floor(i / dim);
      let thatTile = this.getPixel(cx, cy);
      if (thatTile !== MapTileType.Undefined) {
        if (MapTileDef.get(thatTile)?.blocksPath) {
          // TODO add other floor tiles and water
          if (this.internalMap.tiles[i].area == undefined) {
            let areaSize = floodfillArea(cx, cy, areaCounter);
            if (areaSize > largestArea) {
              largestArea = areaSize;
              largestAreaCounter = areaCounter;
            }
            areaCounter = areaCounter + 1;
          }
        }
      }
    }

    //Eliminate smaller areas
    for (let i = 0; i < dim * dim; i++) {
      let areaCounter = this.internalMap.tiles[i].area;
      if (areaCounter != undefined) {
        if (areaCounter != largestAreaCounter) {
          this.internalMap.tiles[i].type = MapTileType.CaveWall;
        }
      }
    }

    let placeStairs = (type: any) => {
      const numberOfTrials = 20;
      for (let i = 0; i < numberOfTrials; i++) {
        let ix = GameState.throwDie(dim) - 1;
        let iy = GameState.throwDie(dim) - 1;
        if (
          this.getPixel(ix, iy) === MapTileType.CaveFloor ||
          this.getPixel(ix, iy) === MapTileType.RuinFloor ||
          this.getPixel(ix, iy) === MapTileType.CityFloor
        ) {
          if (this.internalMap.tiles[iy * dim + ix].item == undefined) {
            this.internalMap.tiles[iy * dim + ix].item = type;
            return true;
          }
        }
      }
      console.log("map", "Couldn't place stairs");
      return false;
    }

    if (tags.includes("stairsDown")) {
      if (!placeStairs(1)) return this.generateMap(size, tags);
    }
    if (tags.includes("stairsUp")) {
      if (!placeStairs(2)) return this.generateMap(size, tags);
    }
    return this.internalMap;
  }

}




