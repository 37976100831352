declare global {
    interface String {
        shuffle(): string;
        extractValue(): number;
        occurrences(a: string, b: boolean): number;
    }


}


export default function () {

    (<any>Array.prototype).randomElement = function () {
        return this[Math.floor(Math.random() * this.length)];
    };



    String.prototype.shuffle = function () {
        var a = this.split("");
        let n = a.length;

        for (let i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    };

    String.prototype.extractValue = function () {
        let string_copy = (" " + this).slice(1);
        while (!"0123456789+-".includes(string_copy[0]))
            string_copy = string_copy.slice(1);
        return Number.parseInt(string_copy);
    };

    String.prototype.occurrences = function (subString, allowOverlapping = false) {
        // this += "";
        subString += "";
        if (subString.length <= 0) return this.length + 1;

        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;

        while (true) {
            pos = this.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }
}

